import { Grid, Typography } from "@mui/material";
import React from "react";

export default function DeliverInternationally() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
        We deliver internationally too!

        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14 }}>
        List International locations we service
We also would like to make sure on the app, once a customer puts in a request all of their delivery details can still go to our CRM we use, so the customer can receive emails etc

        </Typography>
      </Grid>
     
    </Grid>
  );
}
