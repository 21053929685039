import { Navigate, useRoutes } from "react-router-dom";
// layouts
import AuthLayout from "../layout/auth";
import secureLocalStorage from "react-secure-storage";
import ForgotPassword from "../pages/auth/forgot";
import DashboardLayout from "../layout/dashboard";
import Profile from "../pages/dashboard/profile";
import SignIn from "../pages/auth/signIn";
import AdminDashboard from "../pages/dashboard/adminDashboard.js";
import Customer from "../pages/dashboard/customer/index.js";
import DeliveryStaff from "../pages/dashboard/deliveryStaff/index.js";
import QuoteRequest from "../pages/dashboard/quoteRequest";
import Bookings from "../pages/dashboard/bookings";
import PaymentHistory from "../pages/dashboard/paymentHistory";
import Announcements from "../pages/dashboard/Announcements";
import Chat from "../pages/dashboard/chat";
import SlidePage from "../pages/SlidePage/index.js";
import PormoCode from "../pages/dashboard/promoCode/index.js";
import AddPromoCode from "../pages/dashboard/promoCode/addPromocode.js";
import Notification from "../pages/dashboard/notification/notification.js";
import MonthlyDelivery from "../pages/dashboard/deliveryStaff/subcomponents/monthlyRevenue.js";
import RescheduleNanny from "../pages/dashboard/rescheduleNanny/index.js";

export default function Router() {
  const isLoggedIn = secureLocalStorage.getItem(
    process.env.REACT_APP_TOKEN_STORAGE_KEY
  );

  //** When User Logged In (private routing)
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <AdminDashboard /> },
        { path: "customer", element: <Customer /> },
        { path: "deliveryStaff", element: <DeliveryStaff /> },
        { path: "monthly/:id", element: <MonthlyDelivery /> },
        { path: "quoteRequest", element: <QuoteRequest /> },
        { path: "bookings", element: <Bookings /> },
        { path: "paymentHistory", element: <PaymentHistory /> },
        { path: "announcement", element: <Announcements /> },
        { path: "notification", element: <Notification />  },
     
        { path: "chat", element: <Chat /> },
        { path: "profile", element: <Profile /> },
        { path: "promoCode", element: <PormoCode /> },
        { path: "promoCode/addPromoCode", element: <AddPromoCode /> },
        { path: "reschedulenanny", element: <RescheduleNanny/> },
      ],
    },
    //** When User Logged Out (public routing)
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard/app" />,
      children: [
        { path: "login", element: <SignIn /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },

    { path: ":type", element: <SlidePage /> },
  ]);

  return routes;
}
