import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { ThemeProvider, Tooltip, Typography } from "@mui/material";
import theme from "../../../theme";
import { appColors } from "../../../theme/colors";

export default function SideItem(props) {
  const { active = false, disabled, icon, title, index, path, open } = props;
  return (
    <ThemeProvider theme={theme}>
      <ListItem
        key={index}
        // disablePadding
        sx={{
          display: "block",
          ...(active && {
            backgroundColor: "#0887FC",
            color: "white",
          }),
          ...(disabled && {
            backgroundColor: "white",
          }),
          pl: 0,
        }}
      >
        <Link
          to={path}
          style={{
            textDecoration: "none",
            color: active ? "white" : "black",
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            {" "}
            <Tooltip title={title} arrow>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  color: appColors.topbar,
                  justifyContent: "center",
                  ...(active && {
                    color: "white",
                  }),
                }}
              >
                {icon}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    lineHeight: 1.57,
                    fontFamily: "Poppins",
                    color: active ? "white" : "",
                  }}
                >
                  {title}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      </ListItem>
    </ThemeProvider>
  );
}
