/* eslint-disable no-unused-vars */
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext } from "react";
import { DialogTitle } from "@mui/material";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Typography } from "@mui/material";
// import { Avatar } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import UserContext from "../../context/eventContext";

export default function ViewdetailsDialog(props) {
  const { open, handleClose, data, cameFrom } = props;

  const userDetailData = useContext(UserContext);


  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      {cameFrom === "delivery" ? (
        <>
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <DialogTitle
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" User Details"}
              </DialogTitle>
            </Grid>
            <Grid
              sx={{
                p: 1,
              }}
            >
              <Button
                variant="Yes"
                sx={{
                  mt: 0.5,
                  color: "white",
                  backgroundColor: "#299AD5",
                  height: 25,
                  ":hover": {
                    color: "white",
                    backgroundColor: "#5856d6",
                  },
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          {" User Details"}
        </DialogTitle>
      )}
      <Divider />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ margin: 15 }}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {/* <Avatar
              alt="Remy Sharp"
              src={
                data.profileImageUrl
                  ? data.profileImageUrl
                  : "/assets/avatars/avatar-jie-yan-song.png"
              }
              sx={{ width: 56, height: 56, textAlign: "center" }}
            /> */}
          </Typography>
          <br />

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>First Name</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.firstName ? data?.firstName : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Last Name</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.lastName ? data?.lastName : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Country Code</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.countryCode ? data?.countryCode : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Phone Number</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.phoneNumber ? data?.phoneNumber : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Email</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.email ? data?.email : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>User Type</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userType === 2 && "Pet Owner"}
                {data?.userType === 3 && "Breeder"}
                {data?.userType === 4 && "Delivery Staff"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Email Verified</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.isEmailVerified === 0 ? "Pending " : "Approved"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Account Status</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.status === 1 ? "Active " : "Suspended"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Address</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.address ? data.address : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Country</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.country ? data.country : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>City</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.city ? data.city : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Postal Code</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.postalCode ? data.postalCode : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Region </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.region ? data.region : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          {cameFrom === "delivery" && (
            <>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Document </Typography>
                </Grid>
                <Grid>
                  <Link
                    to={data?.docFileFullUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Doc
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>No Of Booking </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.noOfBooking ? data.noOfBooking : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Available To Start </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.availableToStart ? data.availableToStart : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    Experience To Handling{" "}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.haveExperienceToHandling
                      ? data.haveExperienceToHandling
                      : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Airline Worked For </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.airlineWorkedFor ? data.airlineWorkedFor : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <Divider />

      <DialogActions>
        {cameFrom === "customer" ? (
          <>
            <Button
              variant="Yes"
              sx={{
                mt: 3,
                mb: 2,
                color: "white",
                backgroundColor: "#299AD5",
                height: 40,
                ":hover": {
                  color: "white",
                  backgroundColor: "#5856d6",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        ) : (
          ""
          // <>
          //   <Grid container justifyContent={"space-between"}>
          //     {data?.isAdminverified === 2 && (
          //       <>
          //         {" "}
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //         <Button
          //           variant="Yes"
          //           disabled
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //             "&:disabled": {
          //               color: "white",
          //               backgroundColor: "grey",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>
          //       </>
          //     )}
          //     {/* {data?.isAdminverified === 0 && (
          //       <>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>
          //       </>
          //     )}

          //     {data?.isAdminverified === 1 && (
          //       <>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //             "&:disabled": {
          //               color: "white",
          //               backgroundColor: "grey",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>

          //         <Button
          //           variant="Yes"
          //           disabled
          //           sx={{
          //             mt: 3,
          //             mb: 2,

          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //       </>
          //     )} */}
          //   </Grid>
          // </>
        )}
      </DialogActions>
    </Dialog>
  );
}
