/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/loader";
import AdminInput from "../../../components/adminInput";
import AdminCheckbox from "../../../components/adminCheckbox";
import AdminButton from "../../../components/adminButton";
import AdminRadio from "../../../components/adminRadio";
import { Icon } from "@iconify/react";
import { Chip } from "@mui/material";
import { userNotification, getAllUserList } from "../../../store";
import { Autocomplete, Checkbox } from "@mui/material";
import { TextField } from "@mui/material";

export default function index() {
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [formDataError, setFormDataError] = useState({});
  // const [userid, setUserId]=useState([]);
  const { quoteListData, totalCount, isLoading, allUserListData } = useSelector(
    (state) => state.userSlice
  );
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [formError, setFormError] = useState({});
  const [userId, setUserId] = useState([]);
  // const [allUserType, setUserType] = useState([]);
  console.log("formData?.isAllUser ", formData?.isAllUser);
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const userStatus = [
    {
      label: "Send as notification",
      id: 1,
    },
    {
      label: "Send as email",
      id: 2,
    },
    {
      label: "Send as both",
      id: 3,
    },
  ];

  useEffect(() => {
    getAllUser();
  }, []);

  const getAllUser = (val) => {
    let data = {
      language: "en",
      search: val,
    };
    dispatch(getAllUserList(data));
  };

  const handleuserIdArray = (array, newValue) => {
    let userId = Array.isArray(newValue) ? [] : [];
    let userType = Array.isArray(newValue) ? [] : [];
    newValue?.map((data) => {
      userId.push(data?.id);
      userType.push(data?.userType);
    });
    setFormData((formData) => ({
      ...formData,
      userId: userId,
      userType: userType,
    }));
    setFormDataError((formDataError) => ({
      ...formDataError,
      userIds: "",
    }));
  };

  const useTypes = [
    { value: 1, label: "All Users", color: "#00B8D9", isFixed: true },
    { value: 4, label: "All Nanny", color: "#0052CC", isDisabled: true },
    { value: 3, label: "All Breeder", color: "#5243AA" },
    { value: 2, label: "All Petowner", color: "#FF5630", isFixed: true },
  ];

  const handleSend = () => {
    console.log("userdata", formData?.userId);
    if (!formData?.title || !formData?.title.trim()) {
      setFormDataError((formDataError) => ({
          ...formDataError,
          title: "Please enter title",
      }));
      return;
  }
  
    if (!formData?.data || !formData?.data.trim()) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        data: "Please enter message",
      }));
      return;
    }
    if (!formData?.isAllUser && (!formData?.userId || formData.userId === 0)) {
      setFormDataError((formDataError) => ({
          ...formDataError,
          userIds: "Please select users",
      }));
      return;
  }

    if (!formData?.sendAs) {
      return setFormDataError((formDataError) => ({
        ...formDataError,
        sendAs: "Please select how to send your announcement",
      }));
    }
    notificationToUser();
    setFormData({
      title: "",
      data: "",
      isAllUser: true,
      userId: 0,
      sendAs: "",
    });

    // Clear form errors after submission
    setFormDataError({
      title: "",
      data: "",
      userIds: "",
      sendAs: "",
    });
  
  };

 
  const handleUserBox = (checked, id) => {
    // console.log("checked", checked, id);
    if (id === "All" && checked) {
      let temp = [...userData];
      temp.push(2, 3, 4);
      setUserData(temp);
      setFormData((formData) => ({
        ...formData,
        isAllUser: true,
      }));
    } else if (id === "All" && !checked) {
      let temp = [];
      setUserData([]);
      setFormData((formData) => ({
        ...formData,
        isAllUser: false,
      }));
    } else if (id && checked) {
      let temp = [...userData];
      temp.push(id);
      setUserData(temp);
    } else {
      let temp = [...userData];
      temp = temp.filter((item) => item != id);
      setUserData(temp);
    }
  };
  const notificationToUser = () => {
    var data = {
      type: formData?.isAllUser || userData.length > 0 ? 1 : 2,
      userIds: !userData == 0 ? formData?.userId : [],
      title: formData?.title,
      description: formData?.data,
      language: "en",
      notiType: formData?.sendAs,
      userType: userData,
    };
    dispatch(userNotification(data, setShowLoader, setFormData));

  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Announcement </title>
      </Helmet>

      <Card>
        <Box sx={{ background: "white", mt: isMobile ? -2 : 0, py: 2, px: 5 }}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid sx={{ mt: 1 }}>
              <Typography variant="body1" sx={{ fontSize: 18 }}>
                <strong>Send Announcement</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{ mt: 0, py: 2, px: 5, alignItems: "center" }}
        >
          <Grid item xs={12}>
            <Typography>Title</Typography>
            <AdminInput
              // title="Title"
              value={formData?.title}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  title: e.target.value,
                }));
                setFormDataError((formDataError) => ({
                  ...formDataError,
                  title: "",
                }));
              }}
              formError={formDataError.title}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Message</Typography>
            <AdminInput
              rows="5"
              // title="Message"
              value={formData?.data}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  data: e.target.value,
                }));
                setFormDataError((formDataError) => ({
                  ...formDataError,
                  data: "",
                }));
              }}
              formError={formDataError.data}
            />
          </Grid>

          <Grid item xs={12}>
            {userData && userData == 0 ? (
              <>
                <Typography>select user</Typography>
                <Autocomplete
                  sx={{
                    pt: 1,
                    "& .MuiChip-label": {
                      color: "black",
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "black",
                    },
                  }}
                  multiple
                  id="checkboxes-tags-demo"
                  onChange={handleuserIdArray}
                  options={allUserListData ? allUserListData : []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.firstName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      <Typography sx={{ color: "black", pt: 0.8 }}>
                        {" "}
                        {option.firstName}
                      </Typography>
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => {
                        getAllUser(e.target.value);
                        setFormDataError((formDataError) => ({
                          ...formDataError,
                          userIds: "",
                        }));
                      }}
                    />
                  )}
                />

                <Box sx={{ pb: 2 }}>
                  {allUserListData?.users
                    ? allUserListData?.users.map((item, i) => (
                        <Chip
                          label={item.label}
                          variant="outlined"
                          sx={{ m: 1 }}
                          key={i}
                          onDelete={() => console.log(item.label)}
                        />
                      ))
                    : ""}
                </Box>
                <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                  {formDataError.userIds ? formDataError.userIds : ""}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Grid>
        

          <Grid item md={2}>
            <AdminCheckbox
              // checked={formData.isUser}
              handleChange={(val) => {
                handleUserBox(val, "All");
              }}
              label="Send to all Users"
            />
          </Grid>
          <Grid item md={2}>
            <AdminCheckbox
              checked={userData.includes(4)}
              handleChange={(val) => {
                handleUserBox(val, 4);
              }}
              label="Send to all Nanny's"
            />
          </Grid>
          <Grid item md={2}>
            <AdminCheckbox
              checked={userData.includes(3)}
              handleChange={(val) => {
                handleUserBox(val, 3);
              }}
              label="Send to all Breeders"
            />
          </Grid>
          <Grid item md={3}>
            <AdminCheckbox
              checked={userData.includes(2)}
              handleChange={(val) => {
                handleUserBox(val, 2);
              }}
              label="Send to all Pet Owners"
            />
          </Grid>
          <Grid item md={6}>
            <AdminRadio
              radioBtnData={userStatus}
              value={formData.sendAs}
              handleChange={(val) => {
                setFormData((formData) => ({
                  ...formData,
                  sendAs: val,
                }));
              }}
              // handleChange={(val) => {
              //   setFormData((prevFormData) => {
              //     const newSendAs = prevFormData.sendAs || [];
              //     if (val) {
              //       if (!newSendAs.includes(1)) {
              //         return {
              //           ...prevFormData,
              //           sendAs: [...newSendAs, 2],
              //         };
              //       }
              //     } else {
              //       return {
              //         ...prevFormData,
              //         sendAs: newSendAs.filter((item) => item !== 1),
              //       };
              //     }
              //     return prevFormData;
              //   });
              // }}
              formError={formDataError.sendAs}
            />
          </Grid>
          <Grid item xs={6}>
            <AdminButton
              title="Send"
              startIcon={<Icon icon="bi:send" />}
              onClick={handleSend}
            />
          </Grid>
        </Grid>
      </Card>

      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
}
