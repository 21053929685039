import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  getQuoteRequestList,
} from "../../../store";
import moment from "moment/moment";
import Loader from "../../../components/loader/loader";

export default function QuoteRequest() {
  var page = 1;
  var perPage = 10;
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [showLoader, setShowLoader] = useState(false);
  const [pagecheck, setPageCheck] = useState(1);
  const { quoteListData, totalCount, isLoading } =
    useSelector((state) => state.userSlice);
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  useEffect(() => {
    setShowLoader(true);
    quoteRequestList();
  }, []);

  useEffect(() => {
    if (quoteListData) {
      // setShowLoader(false);
    }
  }, [quoteListData]);

  const quoteRequestList = (page, numberOfRows, search) => {
    var data = {
      userType: "",
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: search,
      sort: {
        sortField: "",
        sortIn: "",
      },
      language: "en",
    };
    let isSearch = search ? true : false;
    dispatch(getQuoteRequestList(data, isSearch, setShowLoader));
  };

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;

        quoteRequestList(page, "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        quoteRequestList(page, "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      //localStorage.setItem("socialListRowPerPage", numberOfRows);
      setNumberOfRows(numberOfRows);
      quoteRequestList(page, numberOfRows, "");
    },
  };

  const columns = [
    {
  name: "firstName",
  label: "Name",
  options: {
    filter: true,
    sort: false,
    customBodyRender: (value, tableMeta) => {
      const rowData = quoteListData[tableMeta.rowIndex];
      return (
        <Grid>
          <Typography>{`${value ?? rowData.firstName} ${rowData.lastName}`}</Typography>
        </Grid>
      );
    },
  },
},

    {
      name: "email",
      label: "Email",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid>
              <Typography>{value ?? quoteListData[tableMeta.rowIndex].countryCode+" "+quoteListData[tableMeta.rowIndex].phoneNumber}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "firstDelivery",
      label: "Delivery date",

      options: {
        filter: true,
        sort: false,
      },
      customBodyRender: (value) => {
        return (
          <Typography sx={{ fontSize: 12 }}>{value ? moment(value).format("DD/MM/YYYY") : "-"}</Typography>
        );
      },
    },
    // {
    //   name: "secondDelivery",
    //   label: "2nd requested delivery date",

    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    //   customBodyRender: (value) => {
    //     return (
    //       <Typography sx={{ fontSize: 12 }}>{value ? moment(value).format("DD/MM/YYYY") : "-"}</Typography>
    //     );
    //   },
    // },

    {
      name: "pickupAirpot",
      label: "Pickup airport",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
            <Tooltip title={value.airpotName}>
              <Typography
                sx={{
                  fontSize: 14,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {value ? value.airpotName : "-"}
              </Typography>
            </Tooltip>
          </Box>
          );
        },
      },
    },
    {
      name: "dropAirpot",
      label: "Delivery airport",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value.airpotName}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value.airpotName : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "pets",
      label: "Pets",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "isBreeder",
      label: "Breeder",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value ?"Yes":"No"}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "totalFare",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                ${Number(value).toLocaleString('us')}
              </Typography>
            </Grid>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Quote Request </title>
      </Helmet>
      <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Quote Request</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="App wrapper">
        {isLoading ? (
          <>
            <Grid
              container
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                mt: 2,
                height: 300,
                backgroundColor: "white",
              }}
            >
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            {quoteListData && quoteListData.length > 0 ? (
              <Grid container sx={{ width: "auto" }}>
                <Grid xs={12}>
                  <MUIDataTable
                    data={quoteListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                { !showLoader && <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 300,
                    backgroundColor: "white",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>}
              </>
            )}
          </>
        )}
      </div>
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
}
