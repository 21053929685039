import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { QTPET } from "../../services/apiConstant";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

// create slice
// Slice

const slice = createSlice({
  name: "authSlice",
  initialState: {
    loginData: null,
    isLoading: false,
  },
  reducers: {
    //For Loader
    apiFetching: (state) => {
      state.isLoading = true;
    },

    //For Login
    loginSuccess: (state, action) => {
      state.loginData = action.payload.data;
      state.isLoading = false;
    },
    loginFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For Login
    forgotPasswordSuccess: (state, action) => {
      state.loginData = action.payload.data;
      state.isLoading = false;
    },
    forgotPasswordFailure: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  },
});

const {
  loginSuccess,
  loginFailed,
  apiFetching,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} = slice.actions;

//For Login Function
export const adminLogin = (data, navigate) => async (dispatch) => {
  dispatch(apiFetching());
  try {
    await api.post(QTPET.loginApi, data).then((response) => {
      let result = response.data;
      if (result.status) {
        secureLocalStorage.setItem(
          process.env.REACT_APP_TOKEN_STORAGE_KEY,
          result.data.token
        );
        secureLocalStorage.setItem(
          process.env.REACT_APP_USER_STORAGE_KEY,
          JSON.stringify(result.data)
        );

        dispatch(loginSuccess(result.data));
        navigate("/", { replace: true });
      } else {
        dispatch(loginFailed(response.data));
        toast.error(response.data.message);
      }
    });
  } catch (e) {
    return toast.error(e.message);
  }
};
//For Forgot Password
export const forgotPasswordAdmin = (data, navigate) => async (dispatch) => {
  try {
    await api.post(QTPET.forgotPassword, data).then((response) => {
      if (response) {
        if (response.data.status === true) {
          dispatch(forgotPasswordSuccess(response.data));
          toast.success(response.data.message);
          navigate("/login", { replace: true });
        } else {
          dispatch(forgotPasswordFailure(response.error));
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return toast.error(e.message);
  }
};

export default slice.reducer;
