import { useEffect, useRef, useState } from "react";
import {
  getPromoCodeList,
  deletPromoCode,
  getPromoCodeDetails,
} from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Popover,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { Box } from "@mui/material";
import AdminInput from "../../../components/adminInput";
import MUIDataTable from "mui-datatables";
import { appColors } from "../../../theme/colors";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemButton } from "@mui/material";
import { Icon } from "@iconify/react";
import AdminButton from "../../../components/adminButton";
import { useNavigate } from "react-router-dom";
import ViewPromoCodeDetails from "../../../components/viewPromoCodeDetails";
import AlertDialog from "../../../components/dialog/alertDialog/alertDialog";
import Loader from "../../../components/loader/loader";
import moment from "moment";

const Index = () => {
  const [isLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  var page = 1;
  var perPage = 10;
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pagecheck, setPageCheck] = useState(1);
  const [viewDialog, setViewDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  var mobiletheme = useTheme();
  const dispatch = useDispatch();
  const { promoCodeList, promoCodeDetails } = useSelector(
    (state) => state.promoCodeSlice
  );
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const open = Boolean(anchorEl);
  const [promoCodeId, setPromoCodeId] = useState("");
  var searchInputRef = useRef();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getPromoCodeListFunction();
  }, []);

  const getPromoCodeListFunction = (page, numberOfRows, search) => {
    dispatch(
      getPromoCodeList(
        page ? page : 1,
        numberOfRows ? numberOfRows : perPageRows,
        search ? search : "",
        setShowLoader
      )
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 1) {
      getPromoCodeListFunction(page, perPageRows, e.target.value);
    } else if (e.target.value.length === 0) {
      getPromoCodeListFunction(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    getPromoCodeListFunction(page);
  };

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setPromoCodeId(promoCodeList?.data?.[tableMeta.rowIndex]?._id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAccountAlert = () => {
    setAlertDialogRemoveOpen(true);
    setAnchorEl(null);
  };

  const handleDeleteAccount = () => {
    let requestParams = {
      language: "en",
      codeId: promoCodeId,
    };
    dispatch(deletPromoCode(requestParams, setShowLoader));
  };

  const handleClickOpen = () => {
    var requestParams = {
      language: "en",
      codeId: promoCodeId,
    };
    dispatch(getPromoCodeDetails(requestParams));
    setViewDialog((viewDialog) => ({
      ...viewDialog,
    }));
  };

  const options = {
    count: promoCodeList?.totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;

        getPromoCodeListFunction(page, "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        getPromoCodeListFunction(page, "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      getPromoCodeListFunction(page, numberOfRows, "");
    },
  };

  const columns = [
    {
      name: "title",
      label: "Title",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "code",
      label: "Promo Code",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },

    {
      name: "startDate",
      label: "Start Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{moment(value ? value : "-").format('MM/DD/YYYY')}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "expiredAt",
      label: "End Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
              <Typography>{moment(value ? value : "-").format('MM/DD/YYYY')}</Typography>
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "discountType",
      label: "Discount Type",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value === 2 ? "Amount" : "Percentage"}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "discountValue",
      label: "Discount Value",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          console.log("tableMeta",tableMeta.rowIndex);
          return (
            <Grid>
              <Typography>
               {value ? value :"-"}
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = promoCodeList[tableMeta.rowIndex]?._id;
          return (
            <ThemeProvider theme={theme}>
              <Helmet>
                <title> Customer </title>
              </Helmet>
              <Button
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
              >
                <Icon
                  icon="ph:dots-three-circle-vertical-fill"
                  color={appColors.topbar}
                  width="30"
                  height="30"
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleClickOpen()}>
                      <ListItemIcon>
                        <Icon
                          icon="ri:eye-fill"
                          color="#21618C"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="View"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton onClick={handleDeleteAccountAlert}>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:delete"
                          color="red"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </ThemeProvider>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title> Promo Code </title>
      </Helmet>

      <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            <strong>Promo Code List</strong>
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            pt: 1,
            pb: 1,
            display: "flex",
            justifyContent: isMobile ? "" : "space-between",
          }}
        >
          <Grid item xs={isMobile ? 12 : ""} sx={{ mt: 1.3 }}>
            <AdminInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  getPromoCodeListFunction(page);
                }
              }}
              inputRef={searchInputRef}
              title="Search Promo Code"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{ cursor: "pointer" }}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : ""}>
            <AdminButton
              title="Add Promo Code"
              startIcon={<Icon icon="bi:send" />}
              onClick={() => navigate("addPromoCode")}
            />
          </Grid>
        </Grid>

        <div className="App wrapper">
          {isLoading ? (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  mt: 2,
                  height: 300,
                  backgroundColor: "white",
                }}
              >
                <Grid
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                ></Grid>
              </Grid>
            </>
          ) : (
            <>
              {promoCodeList?.data && promoCodeList?.data?.length > 0 ? (
                <Grid container sx={{ width: "auto" }}>
                  <Grid xs={12}>
                    <MUIDataTable
                      data={promoCodeList?.data}
                      columns={columns}
                      options={options}
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
               { !showLoader &&    <Grid
                    container
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      height: 300,
                      backgroundColor: "white",
                    }}
                  >
                    <Grid
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                      >
                        No Record Found
                      </Typography>
                    </Grid>
                  </Grid>}
                </>
              )}
            </>
          )}
        </div>

        <>
          <ViewPromoCodeDetails
            open={viewDialog}
            handleClose={() => {
              setViewDialog(false);
              setAnchorEl(null);
            }}
            data={promoCodeDetails}
            cameFrom={"customer"}
          />
        </>

        {alertDialogRemoveOpen && (
          <AlertDialog
            open={alertDialogRemoveOpen}
            title="Warning"
            text="Are you sure you want to delete this account?"
            handleClose={setAlertDialogRemoveOpen}
            handleAgree={() => {
              handleDeleteAccount();
            }}
          />
        )}
      </Box>
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
};

export default Index;
