/** @format */

import React, { memo } from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
} from "@mui/material";
import AdminButton from "../../adminButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = (props) => {
  const { title, open, handleClose, handleAgree, text } = props;
  //eslint
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll="paper"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose(false)}
      >
        <DialogTitle>
          <Typography variant="h6" sx={{textTransform:"capitalize"}} >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers="scroll">
          <DialogContentText>
            <Typography variant="body1">{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={() => {
              handleClose(false);
              handleAgree();
            }}
            title={"Yes"}
          />

          <AdminButton title={"No"} onClick={() => handleClose(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any,
};

export default memo(AlertDialog);
