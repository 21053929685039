/** @format */

import React, { memo } from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
} from "@mui/material";
import AdminButton from "../../adminButton";
import AdminInput from "../../adminInput";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../store";
import { Icon } from "@iconify/react";
import Loader from "../../loader/loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePassword = (props) => {
  const { open, handleClose } = props;
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = () => {
    if (!formData.oldPassword) {
      setFormError((formError) => ({
        ...formError,
        oldPassword: "Please enter old password",
      }));
      return;
    }
    if (!formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Please enter a password",
      }));
      return;
    }
    if (formData.newPassword.length <= 7) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Password must be  8 characters ",
      }));
      return;
    }
    if (!formData.confirmPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Please enter a password",
      }));
      return;
    }

    if (formData.confirmPassword !== formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Confirm password dosent match, Try again",
      }));
      return;
    }
    setLoader(true);
    var data = {
      currentPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };

    dispatch(changePassword(data, setLoader, setFormData, handleClose));
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll="paper"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose(false)}
      >
        <DialogTitle>
          <Typography variant="h6">{"Change Password"}</Typography>
        </DialogTitle>
        <DialogContent dividers="scroll">
          <AdminInput
            title="Old password"
            type={showPassword.old ? "text" : "password"}
            value={formData?.oldPassword ? formData?.oldPassword : ""}
            onChange={(e) => {
              if (e) {
                setFormData((formData) => ({
                  ...formData,
                  oldPassword: e.target.value,
                }));
                if (e) {
                  setFormError((formError) => ({
                    ...formError,
                    oldPassword: "",
                  }));
                }
              }
            }}
            formError={formError.oldPassword}
            endIcon={
              <Icon
                icon={!showPassword.old ? "tabler:eye-off" : "eva:eye-outline"}
                width="28"
                height="28"
              />
            }
            PasswordShowClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                old: !showPassword.old,
              }))
            }
          />
          <AdminInput
            title="New password"
            type={showPassword.new ? "text" : "password"}
            value={formData.newPassword ? formData?.newPassword : ""}
            onChange={(e) => {
              if (e) {
                setFormData((formData) => ({
                  ...formData,
                  newPassword: e.target.value,
                }));
                if (e) {
                  setFormError((formError) => ({
                    ...formError,
                    newPassword: "",
                  }));
                }
              }
            }}
            formError={formError.newPassword}
            endIcon={
              <Icon
                icon={!showPassword.new ? "tabler:eye-off" : "eva:eye-outline"}
                width="28"
                height="28"
              />
            }
            PasswordShowClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                new: !showPassword.new,
              }))
            }
          />
          <AdminInput
            title="Confirm password "
            type={showPassword.confirmPassword ? "text" : "password"}
            value={formData?.confirmPassword ? formData?.confirmPassword : ""}
            onChange={(e) => {
              if (e) {
                setFormData((formData) => ({
                  ...formData,
                  confirmPassword: e.target.value,
                }));
                if (e) {
                  setFormError((formError) => ({
                    ...formError,
                    confirmPassword: "",
                  }));
                }
              }
            }}
            formError={formError.confirmPassword}
            endIcon={
              <Icon
                icon={
                  !showPassword.confirmPassword
                    ? "tabler:eye-off"
                    : "eva:eye-outline"
                }
                width="28"
                height="28"
              />
            }
            PasswordShowClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                confirmPassword: !showPassword.confirmPassword,
              }))
            }
          />
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={handleSubmit}
            fullWidth
            // onClick={() => {
            //   handleClose(false);
            //   handleAgree();
            // }}
            title={"Change Password"}
          />

          <AdminButton
            fullWidth
            title={"Close"}
            onClick={() => handleClose(false)}
          />
        </DialogActions>
        {loader && <Loader open={loader} text={"Please Wait"} />}
      </Dialog>
    </>
  );
};

ChangePassword.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any,
};

export default memo(ChangePassword);
