import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { DialogTitle } from "@mui/material";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Typography } from "@mui/material";
// import { Avatar } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import moment from "moment";
// import { Link } from "react-router-dom";
// import UserContext from "../../context/eventContext";

export default function ViewdetailsDialog(props) {
  
  const { open, handleClose, data, cameFrom } = props;

  // const userDetailData = useContext(UserContext);


  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      {cameFrom === "delivery" ? (
        <>
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <DialogTitle
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" Promo Code Details"}
              </DialogTitle>
            </Grid>
            <Grid
              sx={{
                p: 1,
              }}
            >
              <Button
                variant="Yes"
                sx={{
                  mt: 0.5,
                  color: "white",
                  backgroundColor: "#299AD5",
                  height: 25,
                  ":hover": {
                    color: "white",
                    backgroundColor: "#5856d6",
                  },
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          {" Promo Code Details"}
        </DialogTitle>
      )}
      <Divider />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ margin: 15 }}
        >
         
          <br />

          
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Title</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.title ? data?.title : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}> Code</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.code ? data?.code : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Start Date</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.startDate ? moment(data?.startDate).format("MM/DD/YYYY") : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Expiry Date </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.expiredAt ? moment(data?.expiredAt).format("MM/DD/YYYY")  : "N/A"}
              </Typography>
            </Grid>
          </Grid>

    

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Discount Type</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.discountType === 2 ? "Amount": "Percentage"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Discount Value</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.discountValue ? data?.discountValue : data?.discountValue}
              </Typography>
            </Grid>
          </Grid>

          

         
        
         
        </DialogContentText>
      </DialogContent>
      <Divider />

      <DialogActions>
        {cameFrom === "customer" ? (
          <>
            <Button
              variant="Yes"
              sx={{
                mt: 3,
                mb: 2,
                color: "white",
                backgroundColor: "#299AD5",
                height: 40,
                ":hover": {
                  color: "white",
                  backgroundColor: "#5856d6",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        ) : (
          ""
          // <>
          //   <Grid container justifyContent={"space-between"}>
          //     {data?.isAdminverified === 2 && (
          //       <>
          //         {" "}
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //         <Button
          //           variant="Yes"
          //           disabled
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //             "&:disabled": {
          //               color: "white",
          //               backgroundColor: "grey",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>
          //       </>
          //     )}
          //     {/* {data?.isAdminverified === 0 && (
          //       <>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>
          //       </>
          //     )}

          //     {data?.isAdminverified === 1 && (
          //       <>
          //         <Button
          //           variant="Yes"
          //           sx={{
          //             mt: 3,
          //             mb: 2,
          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //             "&:disabled": {
          //               color: "white",
          //               backgroundColor: "grey",
          //             },
          //           }}
          //           onClick={forReject}
          //         >
          //           Rejected
          //         </Button>

          //         <Button
          //           variant="Yes"
          //           disabled
          //           sx={{
          //             mt: 3,
          //             mb: 2,

          //             color: "white",
          //             backgroundColor: "#299AD5",
          //             height: 40,
          //             ":hover": {
          //               color: "white",
          //               backgroundColor: "#5856d6",
          //             },
          //           }}
          //           onClick={handleAgree}
          //         >
          //           Approved
          //         </Button>
          //       </>
          //     )} */}
          //   </Grid>
          // </>
        )}
      </DialogActions>
    </Dialog>
  );
}
