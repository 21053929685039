import React, { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getCustomerList,
  loyalMember,
  profileDetail,
  suspendAccount,
} from "../../../store/slice/users";
import AdminInput from "../../../components/adminInput";
import { useEffect } from "react";
import { appColors } from "../../../theme/colors";
import ViewdetailsDialog from "../../../components/viewdetailsDialog";
import Loader from "../../../components/loader/loader";
import { Box } from "@mui/material";
import AlertDialog from "../../../components/dialog/alertDialog/alertDialog";

export default function Customer() {
  var page = 1;
  var perPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [searchValue, setSearchValue] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  var [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [loyalAlertDialog, setLoyalAlertDialog] = useState(false);

  const [userStatusDialog, setUserStatusDialog] = useState(false);
  const [pagecheck, setPageCheck] = useState(1);
  const { customerListData, profileDetailData, totalCount, isLoading } =
    useSelector((state) => state.userSlice);
   
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  var searchInputRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    customerList();
    setShowLoader(true);
  }, []);

  useEffect(() => {
    if (customerListData) {
      setShowLoader(false);
    }
  }, [customerListData]);

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta.rowIndex);
  };
  const handleClose = () => {
    setViewDialog(false);
    setAnchorEl(null);
  };
  const customerList = (page, numberOfRows, search) => {
    var data = {
      userType: "",
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: search,
      sort: {
        sortField: "",
        sortIn: "",
      },
    };
    let isSearch = search ? true : false;
    dispatch(getCustomerList(data, isSearch, setShowLoader));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 1) {
      customerList(page, perPageRows, e.target.value);
    } else if (e.target.value.length === 0) {
      customerList(page);
    }
  };
  const handleClickOpen = (rowObj) => {
    var requestParams = {
      userId: rowObj,
    };
    dispatch(profileDetail(requestParams, setShowLoader));
    setViewDialog((viewDialog) => ({
      ...viewDialog,
    }));
  };

  const handleChange = (value, id) => {
    var data = {
      type: 1,
      userId: id,
      status: value === 1 ? "2" : "1",
    };
    dispatch(suspendAccount(data, setShowLoader));
    // setAccountStatus(value);
  };

  const handleDeleteAccount = () => {
    setAlertDialogRemoveOpen(true);
    setAnchorEl(null);
  };

  

  const deleteAccount = () => {
    let userId = customerListData[selectedRowIndex]?.userId;
    var data = {
      type: 1,
      userId: userId,
      status: 3,
    };
    dispatch(deleteUser(data, setShowLoader));
  };

  const convertToLoyal = () => {
    let userId = customerListData[selectedRowIndex]?.userId;
    var data = {
      language: "en",
      userId: userId,
      status: customerListData[selectedRowIndex].isLoyalMember  === true ? false :true
    };
    dispatch(loyalMember(data, setShowLoader));
  };


  const open = Boolean(anchorEl);

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      perPage: 10,
      search: "",
    };
    let isSearch = false;
    dispatch(getCustomerList(data, isSearch, setShowLoader));
  };
  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;

        customerList(page, "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        customerList(page, "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      //localStorage.setItem("socialListRowPerPage", numberOfRows);
      setNumberOfRows(numberOfRows);
      customerList(page, numberOfRows, "");
    },
  };
  const columns = [
    {
      name: "name",
      label: "Name",

      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "email",
      label: "Email",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "noOfBooking",
      label: "No Of Booking ",

      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "userType",
      label: "User Type",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value === 2 ? "Pet Owner" : value === 3 ? "Breeder" : "Nanny"}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                <span style={{ color: "green" }}>
                  {value === 1 && "Active"}
                </span>
                <span style={{ color: "red" }}>
                  {value === 2 && "Suspended"}
                </span>

                <span style={{ color: "red" }}>{value === 3 && "Deleted"}</span>
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "status",
      label: "Suspend/Active Account",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid>
              <Typography>
                <Switch
                  checked={value === 1 ? true : false}
                  onChange={() => {
                    selectedRowIndex = tableMeta.rowIndex;
                    setSelectedRowIndex(tableMeta.rowIndex);
                    setUserStatusDialog(true);
                  }}
                  color="warning"
                />
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "isLoyalMember",
      label: "Loyal Member Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                <span >
                  {value === true ? "Yes" : "No"}
                </span>
                

                <span style={{ color: "red" }}>{value === 3 && "Deleted"}</span>
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = customerListData[tableMeta.rowIndex]?.userId;

          return (
            <ThemeProvider theme={theme}>
              <Helmet>
                <title> Customer </title>
              </Helmet>
              <Button
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
              >
                <Icon
                  icon="ph:dots-three-circle-vertical-fill"
                  color={appColors.topbar}
                  width="30"
                  height="30"
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleClickOpen(id)}>
                      <ListItemIcon>
                        <Icon
                          icon="ri:eye-fill"
                          color="#21618C"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="View"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                      setLoyalAlertDialog(true),
                      setAnchorEl(null)
                    }}>
                      <ListItemIcon>
                        <Icon
                              icon={
                                customerListData[selectedRowIndex]?.isLoyalMember === true
                                  ? "material-symbols:group-remove-outline":"tdesign:member"
                              }
                          color="grey"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary= { customerListData[selectedRowIndex]?.isLoyalMember  === false ?  "Loyalty Member" :"Remove Loyal Member"}
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleDeleteAccount()}>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:delete"
                          color="red"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </ThemeProvider>
          );
        },
      },
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Customer </title>
      </Helmet>

      <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Customer</strong>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={isMobile ? 12 : 3}>
            <AdminInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  customerList(page);
                }
              }}
              inputRef={searchInputRef}
              title="Search Customer"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{cursor:"pointer"}}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="App wrapper">
        {isLoading ? (
          <>
            <Grid
              container
              item
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                mt: 2,
                height: 300,
                backgroundColor: "white",
              }}
            >
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            {customerListData && customerListData.length > 0 ? (
              <Grid container sx={{ width: "auto" }}>
                <Grid xs={12}>
                  <MUIDataTable
                    data={customerListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                { !showLoader && <Grid
                  container
                  item
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 300,
                    backgroundColor: "white",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>}
              </>
            )}
          </>
        )}
      </div>
      {viewDialog && (
        <>
          <ViewdetailsDialog
            open={viewDialog}
            handleClose={() => {
              setViewDialog(false);
              setAnchorEl(null);
            }}
            data={profileDetailData}
            cameFrom={"customer"}
          />
        </>
      )}

      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
      {(selectedRowIndex == 0 || selectedRowIndex) && (
        <AlertDialog
          open={userStatusDialog}
          title="Warning"
          text={`Are you want to sure ${customerListData[selectedRowIndex].status == 1 ? "Suspend" : "Active"} this account`}
          handleClose={setUserStatusDialog}
          handleAgree={() => {
            handleChange(
              customerListData[selectedRowIndex]?.status,
              customerListData[selectedRowIndex]?.userId
            );
          }}
        />
      )}
      {alertDialogRemoveOpen && (
        <AlertDialog
          open={alertDialogRemoveOpen}
          title="Warning"
          text="Are you want to sure delete this account"
          handleClose={setAlertDialogRemoveOpen}
          handleAgree={() => {
            deleteAccount();
          }}
        />
      )}

{loyalAlertDialog && (
        <AlertDialog
          open={loyalAlertDialog}
          title="Warning"
          text={customerListData[selectedRowIndex]?.isLoyalMember === false ?"Are you sure you want to include this customer in loyalty member ?" :"Are you sure you want to remove this customer in loyalty member ?"} 
          handleClose={setLoyalAlertDialog}
          handleAgree={() => {
            convertToLoyal();
          }}
        />
      )}

      
    </ThemeProvider>
  );
}
