/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useId, useState } from "react";
import { DialogTitle } from "@mui/material";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import defaultImg from "../../assets/img/default.jpg";
import UserContext from "../../context/eventContext";

import { approveNannyRequest } from "../../store";
import { useDispatch } from "react-redux";
import AlertDialog from "../dialog/alertDialog/alertDialog";
import { appColors } from "../../theme/colors";
import { useTheme } from "@emotion/react";
import moment from "moment";
import Loader from "../loader/loader";

export default function ViewdRequestDialog({ ...props }) {
  const {
    open,
    handleClose,
    getRequestListData,
    profileData,
    title,
    setShowLoader,
    showRequestLoader
  } = props;

  const userDetailData = useContext(UserContext);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [nanyId, setNanyId] = useState();

  const dispatch = useDispatch();
  console.log("data new", profileData);
  const handleclose = () => {
    setConfirmDialog(false);
  };

  const handleOpen = (id) => {
    setNanyId(id);
    setConfirmDialog(true);
  };

  const handleAgree = () => {
    const requestParam = {
      language: "en",
      nannyId: nanyId,
      bookingId: getRequestListData?.bookingDetails?._id,
    };
    dispatch(approveNannyRequest(requestParam, setShowLoader));
  };

  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ background: "	#FAF9F6" }}    style={{overflowY:"scroll"}}
      className="overflow">
        <DialogContentText id="alert-dialog-slide-description">
          <Stack>
            <div>
              <Icon
                icon="material-symbols-light:flightsmode"
                style={{ color: appColors.topbar }}
              />
            </div>
          </Stack>

          {profileData?.length > 0 ? (
            <>
            

              <Grid container justifyContent={"space-between"}>
                <Grid item display={"flex"} sx={{ p: 1 }}>
                  <Grid item xs={isMobile ? 4 : 6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 16 }}>
                      Booking Id&nbsp;:&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontSize: 16 }}>
                      {getRequestListData?.bookingDetails?.bookingNumber}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    p: 1,
                  }}
                  xs={isMobile ? 8 : 6}
                >
                  <Grid container sx={{ textAlign: "right" }}>
                    <Grid item xs={isMobile ? 12 : 6} display={"flex"}>
                      <Grid item>
                        <Typography
                          sx={{ color: appColors?.topbar, fontSize: 16 }}
                        >
                          From&nbsp;:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ fontSize: 12, marginTop: 0.8 }}>
                          {
                            getRequestListData?.bookingDetails?.pickAirpot
                              ?.airpotName
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} display={"flex"}>
                      <Grid item>
                        <Typography
                          sx={{ color: appColors?.topbar, fontSize: 16 }}
                        >
                          {" "}
                          To&nbsp;:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 12,
                            marginTop: 0.3,
                            textAlign: "right",
                          }}
                        >
                          {
                            getRequestListData?.bookingDetails?.deliveredAirpot
                              ?.airpotName
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                {profileData?.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={isMobile ? 12 : 4}>
                        <Card
                          item
                          key={index}
                          sx={{ p: 1, m: 2, boxShadow: 3 }}
                          // sx={{ borderRadius: 2, p: 1, m: 2, border: "1px solid" }}
                          elevation={3}
                        >
                          <Box sx={{ textAlign: "center" }}>
                            <img
                              src={
                                item && item.userId && item.userId.profileImage
                                  ? item.userId.profileImage
                                  : defaultImg
                              }
                              alt=""
                              style={{
                                width: 100,
                                height: 100,
                                borderRadius: 50,
                              }}
                            />
                          </Box>

                          <Grid
                            container
                            justifyContent="center"
                            alignContent="center"
                          >
                            <Grid
                              item
                              xs={isMobile ? 6 : 5}
                              sx={{ textAlign: "left" }}
                            >
                              <Typography sx={{ fontSize: 14, mb: 2 }}>
                                {" "}
                                Nanny Name
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={isMobile ? 6 : 5}
                              sx={{ textAlign: "right" }}
                            >
                              {" "}
                              <Typography sx={{ fontSize: 14, mb: 2 }}>
                                {" "}
                                {item.userId.firstName+'  '+item.userId.lastName}
                              </Typography>{" "}
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >Pay
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Airline
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Tooltip title={item.airlineName}>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      mb: 2,
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.airlineName}
                                  </Typography>{" "}
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Flight Number
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Tooltip title={item.flightNumber}>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      mb: 2,
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.flightNumber}
                                  </Typography>{" "}
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Pickup Date & Time
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {moment(item.pickupDate).format("MM/DD/YYYY")}
                                  ,&nbsp;
                                  {moment(item.pickupTime)
                                    .local()
                                    .format("hh:mm A")}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Delivered Date & Time
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {moment(item?.deliveryDate).format(
                                    "MM/DD/YYYY"
                                  )}
                                  ,&nbsp;
                                  {moment(item?.deliveredTime)
                                    .local()
                                    .format("hh:mm A")}
                                </Typography>{" "}
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Pet Breed
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {item.petBreed}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Pet Age
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {item.petAge}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Pet Weight
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {item.petWeight}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "left" }}
                              >
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  Number Of Pets
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={isMobile ? 6 : 5}
                                sx={{ textAlign: "right" }}
                              >
                                {" "}
                                <Typography sx={{ fontSize: 14, mb: 2 }}>
                                  {item.petCount}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                          </Grid>
                          {getRequestListData.isAssigned === 1 &&
                            item.isAssigned === 1 &&
                            item.status === 0 && (
                              <Button
                                variant="Yes"
                                sx={{
                                  mt: 1,
                                  mb: 2,
                                  color: "white",
                                  backgroundColor: "#299AD5",
                                  height: 40,
                                  ":hover": {
                                    color: "white",
                                    backgroundColor: "#5856d6",
                                  },
                                  width: "100%",
                                }}
                                onClick={() => handleOpen(item?.userId?._id)}
                              >
                                Approve
                              </Button>
                            )}

                          {item.isAssigned === 0 && item.status === 1 && (
                            <Button
                              variant="Yes"
                              sx={{
                                mt: 1,
                                mb: 2,
                                color: "white",
                                backgroundColor: appColors.successGreenColor,
                                height: 40,
                                // ":hover": {
                                //   color: "white",
                                //   backgroundColor: "#5856d6",
                                // },

                                width: "100%",
                              }}
                              disabled={true}
                            >
                              <p style={{ color: "white" }}> Approved</p>
                            </Button>
                          )}
                          {item.isAssigned === 0 && item.status === 0 && (
                            <Button
                              variant="Yes"
                              sx={{
                                mt: 1,
                                mb: 2,
                                color: "white",
                                backgroundColor: "red",
                                height: 40,
                                ":hover": {
                                  color: "white",
                                  backgroundColor: "#5856d6",
                                },

                                width: "100%",
                              }}
                              disabled={true}
                            >
                              <p style={{ color: "white" }}> Declined</p>
                            </Button>
                          )}
                        </Card>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
{!showRequestLoader && <Box sx={{ textAlign: "center" }}>
                <Typography variant="h4">No Nanny Request Found</Typography>
              </Box>}
              
            </>
          )}
          <DialogActions>
            <Button
              variant="Yes"
              sx={{
                mt: 1,
                mb: 2,
                color: "white",
                backgroundColor: "#299AD5",
                height: 40,
                ":hover": {
                  color: "white",
                  backgroundColor: "#5856d6",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>
      <Divider light={true} />

      {confirmDialog && (
        <AlertDialog
          open={confirmDialog}
          text="Are you sure to approve these flight details ? "
          handleClose={handleclose}
          handleAgree={handleAgree}
          sx={{ fontSize: 16 }}
        />
      )}
      {showRequestLoader && <Loader open={showRequestLoader}/>}
    </Dialog>
  );
}
