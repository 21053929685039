import {
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import "./index.css";

export default function AdminCheckbox(props) {
  const {
    checked,
    handleChange,
    label,
    position,
    formError
  } = props;
  return (
    <>
      <FormControlLabel
        checked={checked}
        control={<Checkbox />}
        label={label}
        labelPlacement={position}
        onChange={(e)=>handleChange(e.target.checked)}
      />
        <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
