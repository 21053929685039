import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Card } from '@mui/material';
import React from 'react';
import { styled } from "@mui/material";

const DeliveryDashboard = ({ ...props }) => {
    const {
        color,
        backgroundColor,
        icon,
        title,
        count
    } = props;

    const StyledIcon = styled("div")(({ theme }) => ({
        margin: "auto",
        display: "flex",
        borderRadius: "5px",
        alignItems: "center",
        width: theme.spacing(7),
        height: theme.spacing(7),
        justifyContent: "center",
        marginBottom: theme.spacing(3),
        
      }));

    return (
        <Card variant="solid" sx={{ pt: 4,pb:2 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <StyledIcon
                        sx={{
                            color: color,
                            backgroundColor: backgroundColor,
                        }}
                    >
                        <Icon icon={icon} width="25" height="25" />
                    </StyledIcon>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {title}
                    </Typography>
                    <Typography style={{ fontSize: 28, fontWeight: 500 }}>
                        {count}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};
export default React.memo(DeliveryDashboard);
