import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../services";
import { QTPET } from "../../services/apiConstant";

// Slice

const slice = createSlice({
  name: "userSlice",
  initialState: {
    //For Loader
    isLoading: false,

    //For Listing
    customerListData: null,
    deliveryStaffData: null,
    userListData: null,
    userListCount: null,
    quoteListData: [],
    bookingData: [],
    paymentHistory: [],
    allUserListData: [],
    profileData: [],
    NotificationListData: [],
    NannyList: [],
    reassignNannyList: [],

    //For Detail
    getUserDetailData: null,
    adminProfileData: null,
    profileDetailData: null,
    dashboardData: null,
    bookingDetailData: null,
    UnReadCount: null,
    getMonthlyRevenueData: [],
    //For Suspend/Delete
    // suspendAccountData: null,

    // Noitification
    sendNotification: null,
    // superAdmin
    getRequestListData: [],
    approveNannyRequestBooking: null,
    upDateNanny: [],
    ReadNoticationData: null,
  },
  reducers: {
    //for loader
    apiFetching: (state) => {
      state.isLoading = true;
    },

    //For listing
    customerListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.vendorListData = [];
      }
      state.customerListData = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
    },

    allUserListSuccess: (state, action) => {
      state.allUserListData = action.payload;
    },

    deliveryStaffSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.deliveryStaffData = [];
      }
      state.deliveryStaffData = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
      if (!action.payload.data) {
        state.profileDetailData = null;
      }
    },

    quoteRequestSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.quoteListData = [];
      }
      state.quoteListData = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
    },
    bookingListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.bookingData = [];
      }
      state.bookingData = action.payload.data;
      console.log("data list", action.payload.data);
      state.totalCount = action.payload.total;

      state.isLoading = false;
    },

    paymentHistoryListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.paymentHistory = [];
      }
      state.paymentHistory = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
    },

    //For Delete

    deleteUserSuccess: (state, action) => {
      let objId = action.payload?.data?.userId;
      const index = state.customerListData.findIndex(
        (item) => item.userId === objId
      );
      state.customerListData[index] = action.payload.data;
    },
    suspendAccoutSuccess: (state, action) => {
      let objId = action.payload?.data?.userId;
      const index = state.customerListData.findIndex(
        (item) => item.userId === objId
      );
      state.customerListData[index] = action.payload.data;
      state.isLoading = false;
    },

    loyalMemberSuccess: (state, action) => {
      let objId = action.payload?.data?.userId;
      const index = state.customerListData.findIndex(
        (item) => item.userId === objId
      );
      state.customerListData[index] = action.payload.data;
      state.isLoading = false;
      toast.success(action.payload.message);
    },
    suspendAccoutDeliverySuccess: (state, action) => {
      let objId = action.payload?.data?.userId;
      const index = state.deliveryStaffData.findIndex(
        (item) => item.userId === objId
      );
      state.deliveryStaffData[index] = action.payload.data;
      state.isLoading = false;
    },

    //for Detail

    adminProfileDetailSuccess: (state, action) => {
      state.adminProfileData = action.payload.data;
    },
    adminProfileDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },

    profileDetailSuccess: (state, action) => {
      state.profileDetailData = action.payload.data;
      console.log("index", action.payload);
    },
    profileDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    dashboardDataSuccess: (state, action) => {
      state.dashboardData = action.payload;
    },
    bookingDetailSuccess: (state, action) => {
      state.bookingDetailData = action.payload;
      console.log("details", action.payload);
    },
    updateUserDetailSuccess: (state, action) => {
      state.profileDetailData = action.payload.data;
    },
    clearReuestListSuccess: (state) => {
      state.profileData = null;
    },

    // Notification
    userNotificationSuccess: (state, action) => {
      state.sendNotification = action.payload;
      console.log("notification data", action.payload);
    },
    getRequestListSuccess: (state, action) => {
      console.log("state", state);
      state.getRequestListData = action.payload;
      state.profileData = action.payload.data;
      state.isLoading = false;
    },

    approveNannyRequestDataSucces: (state, action) => {
      console.log("old", JSON.stringify(state.profileData));
      const newObjId = action.payload.updateId;

      const suspendIndex = state.profileData.findIndex(
        (item) => item.userId._id === newObjId
      );

      state.profileData[suspendIndex] = action.payload;
      console.log("new", JSON.stringify(state.profileData));
    },
    nannyDocSuccess: (state, action) => {
      state.profileDetailData = action.payload.data;
    },
    notificationListSuccess: (state, action) => {
      state.NotificationListData = action.payload;
    },
    ReadnotificationListSuccess: (state, action) => {
      state.ReadNoticationData = action.payload;
    },
    UnReadCountSuccess: (state, action) => {
      state.UnReadCount = action.payload;
    },
    monthlyRevenueSuccess: (state, action) => {
      state.getMonthlyRevenueData = action.payload.data;
      state.totalCount = action.payload.data.totalCount;
    },
    selectNannySuccess: (state, action) => {
      state.NannyList = action.payload;
    },
    reassignBookingtoNannySuccess: (state, action) => {
      state.reassignNannyList = action.payload;
      let id = action.payload.id;
      let index = state.bookingData?.findIndex((item) =>item._id  === id)
      state.bookingData[index] = action.payload.checkResponsej;
    },
  },
});

const {
  customerListSuccess,
  deliveryStaffSuccess,
  profileDetailSuccess,
  profileDetailFailed,
  deleteUserSuccess,
  suspendAccoutSuccess,
  loyalMemberSuccess,
  suspendAccoutDeliverySuccess,
  adminProfileDetailSuccess,
  adminProfileDetailFailed,
  quoteRequestSuccess,
  bookingListSuccess,
  dashboardDataSuccess,
  bookingDetailSuccess,
  updateUserDetailSuccess,
  paymentHistoryListSuccess,
  userNotificationSuccess,
  allUserListSuccess,
  getRequestListSuccess,
  approveNannyRequestDataSucces,
  nannyDocSuccess,
  clearReuestListSuccess,
  notificationListSuccess,
  ReadnotificationListSuccess,
  UnReadCountSuccess,
  monthlyRevenueSuccess,
  selectNannySuccess,
  reassignBookingtoNannySuccess,
  
} = slice.actions;

// *** For Listing Functions
export const getCustomerList =
  (data, isSearch, setShowLoader) => async (dispatch) => {
    setShowLoader(isSearch ? false : true);
    try {
      await api.post(QTPET.getCustomerList, data).then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(
              customerListSuccess({
                ...response.data,
                page: data.page,
              })
            );
          } else {
            setShowLoader(false);
            dispatch(
              customerListSuccess({
                ...response.data,
                page: data.page,
              })
            );
            toast.error(response.error);
          }
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeliveryStaffList =
  (data, isSearch, setShowLoader) => async (dispatch) => {
    setShowLoader(isSearch ? false : true);
    try {
      await api.post(QTPET.getDeliveryStaff, data).then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(
              deliveryStaffSuccess({
                ...response.data,
                page: data.page,
              })
            );
          } else {
            dispatch(
              deliveryStaffSuccess({
                ...response.data,
                page: data.page,
              })
            );
            toast.error(response.error);
          }
          setShowLoader(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDashboardData = (data, setShowLoader) => async (dispatch) => {
  setShowLoader(true);
  try {
    await api.post(QTPET.getDashboardData, data).then((response) => {
      if (response) {
        if (response.data.status) {
          dispatch(dashboardDataSuccess(response.data.data));
          setShowLoader(false);
        } else {
          dispatch(dashboardDataSuccess(response.data.data));
          toast.error(response.error);
          setShowLoader(false);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// ** For Password Related Functions
export const changePassword =
  (requestParam, setLoader, setFormData, handleClose) => async () => {
    api
      .post(`${QTPET.changePassword}`, requestParam)
      .then((response) => {
        let result = response.data;
        if (result.status) {
          let temp = {};
          setFormData(temp);
          setLoader(false);
          toast.success(result.message);
          handleClose(false);
        } else {
          setLoader(false);
          toast.error(result.message);
        }
      })
      .catch((e) => {
        return console.error(e.message);
      });
  };

//For Delete Functions

export const deleteUser =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api.post(QTPET.suspendAccount, requestParams).then((response) => {
        if (response) {
          let id = requestParams.id;
          dispatch(
            deleteUserSuccess({
              ...response.data,
              deleteIndexId: id,
            })
          );
        }
        setShowLoader(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const suspendAccountDelivery =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api
        .post(QTPET.suspendAccountDelivery, requestParams)
        .then((response) => {
          if (response.data.status) {
            let id = requestParams.id;
            dispatch(
              suspendAccoutDeliverySuccess({
                ...response.data,
                deleteIndexId: id,
              })
            );
            dispatch(updateUserDetailSuccess(response.data));
            setShowLoader(false);
            toast.success(response.data.message);
          } else {
            toast.success(response.data.message);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const suspendAccount =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api.post(QTPET.suspendAccount, requestParams).then((response) => {
        if (response) {
          let id = requestParams.id;
          dispatch(
            suspendAccoutSuccess({
              ...response.data,
              deleteIndexId: id,
            })
          );
        }
      });
      setShowLoader(false);
    } catch (e) {
      return console.error(e.message);
    }
  };

//For Added Loyal Member

export const loyalMember =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api.post(QTPET.loyalMember, requestParams).then((response) => {
        if (response) {
          let id = requestParams.id;
          dispatch(
            loyalMemberSuccess({
              ...response.data,
              updateIndex: id,
            })
          );
          toast.success(response.message);
        }
      });
      setShowLoader(false);
    } catch (e) {
      return console.error(e.message);
    }
  };

//For Detail Detail Function

export const profileDetail =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api.post(QTPET.profileDetail, requestParams).then((response) => {
        if (response) {
          dispatch(profileDetailSuccess(response.data));
        } else {
          dispatch(profileDetailFailed(response.error));
        }
        setShowLoader(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminProfileDetails =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api
        .post(QTPET.getAdminProfileDetails, requestParams)
        .then((response) => {
          if (response) {
            dispatch(adminProfileDetailSuccess(response.data));
          } else {
            dispatch(adminProfileDetailFailed(response.error));
          }
          setShowLoader(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getQuoteRequestList =
  (data, isSearch, setShowLoader) => async (dispatch) => {
    setShowLoader(isSearch ? false : true);
    try {
      await api.post(QTPET.getQuoteRequestList, data).then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(
              quoteRequestSuccess({
                ...response.data,
                page: data.page,
              })
            );
          } else {
            toast.error(response.error);
          }
          setShowLoader(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getbookingList =
  (data, isSearch, setShowLoader) => async (dispatch) => {
    setShowLoader(isSearch ? false : true);
    try {
      await api.post(QTPET.getBookingList, data).then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(
              bookingListSuccess({
                ...response.data,
                page: data.page,
              })
            );
          } else {
            dispatch(
              bookingListSuccess({
                ...response.data,
                page: data.page,
              })
            );
            toast.error(response.error);
          }
          setShowLoader(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  

export const bookingDetail = (data) => async (dispatch) => {
  try {
    await api
      .get(`${QTPET.getBookingDetails}?bookingId=${data}&language=en`)
      .then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(bookingDetailSuccess(response.data.data));
          } else {
            toast.error(response.error);
          }
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPaymentHistoryList =
  (data, isSearch, setShowLoader) => async (dispatch) => {
    setShowLoader(isSearch ? false : true);
    try {
      await api.post(QTPET.getPaymentHistoryList, data).then((response) => {
        if (response) {
          if (response.data.status) {
            dispatch(
              paymentHistoryListSuccess({
                ...response.data,
                page: data.page,
              })
            );
          } else {
            dispatch(
              paymentHistoryListSuccess({
                ...response.data,
                page: data.page,
              })
            );

            toast.error(response.error);
          }
          setShowLoader(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const userNotification =
  (data, setShowLoader, setFormData) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api.post(QTPET.NotififyUser, data).then((response) => {
        if (response) {
          dispatch(userNotificationSuccess(response));
          toast.success(response.data.message);
          setFormData({});
        }
        setShowLoader(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAllUserList = (data) => async (dispatch) => {
  try {
    await api.post(QTPET.getAllUserList, data).then((response) => {
      if (response) {
        if (response.data.status) {
          dispatch(allUserListSuccess(response?.data?.data[0]));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const selectNanny = (data) => async (dispatch) => {
  try {
    await api.post(QTPET.selectNanny, data).then((response) => {
      if (response) {
        if (response.data.status) {
          console.log("response.data", response.data);
          dispatch(selectNannySuccess(response.data));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const reassignBookingtoNanny = (data) => async (dispatch) => {
  try {
    await api.post(QTPET.reassignBookingtoNanny, data).then((response) => {
      if (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          dispatch(reassignBookingtoNannySuccess({
            ...response.data,
            id:data.bookingId
          }));
        } else {
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRequestListDetail =
  (data, setShowLoader) => async (dispatch) => {
    setShowLoader(true);

    try {
      await api
        .get(`${QTPET.getRequestList}?bookingId=${data?._id}&language=en`)
        .then((response) => {
          if (response) {
            if (response.data.status) {
              dispatch(getRequestListSuccess(response.data));
            } else {
              toast.error(response.error);
            }
          }
          setShowLoader(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const clearReuestList = () => async (dispatch) => {
  dispatch(clearReuestListSuccess());
};
export const approveNannyRequest =
  (data, setShowLoader) => async (dispatch) => {
    try {
      await api.post(QTPET.approveNannyRequest, data).then((response) => {
        if (response) {
          if (response.data.status) {
            var requestParams = {
              _id: data?.bookingId,
            };
            let updateId = data.nannyId;
            dispatch(
              approveNannyRequestDataSucces({
                ...response.data.checkResponsej,
                updateId: updateId,
              })
            );
            dispatch(getRequestListDetail(requestParams));
            toast.success(response.data.message);
          }
          setShowLoader(false);
        }
      });
    } catch (e) {
      toast.success(e.message);
      return console.error(e.message);
    }
  };
export const upDateNannyDocs =
  (requestParams, setShowLoader) => async (dispatch) => {
    setShowLoader(true);
    try {
      await api
        .post(QTPET.updateNannyDocStatus, requestParams)
        .then((response) => {
          if (response) {
            let id = requestParams.id;
            dispatch(
              nannyDocSuccess({
                ...response.data,
                updateIndex: id,
              })
            );
            toast.success(response.data.message);
          }
        });
      setShowLoader(false);
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getNoticationList = (data) => async (dispatch) => {
  try {
    await api
      .get(
        `${QTPET.NotificationList}?language=${data?.language}&page=${data?.page}&perPage=${data?.perPage}`
      )
      .then((response) => {
        if (response) {
          dispatch(notificationListSuccess(response.data));
        } else {
          toast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const ReadNotication = (data) => async (dispatch) => {
  try {
    await api
      .get(
        `${QTPET.ReadNotification}?language=${data?.language}&page=${data?.notificationId}`
      )
      .then((response) => {
        if (response) {
          dispatch(ReadnotificationListSuccess(response.data));
        } else {
          toast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const UnreadReadNotification = (data) => async (dispatch) => {
  try {
    await api
      .get(`${QTPET.UnReadCount}?language=${data?.language}`)
      .then((response) => {
        if (response) {
          dispatch(UnReadCountSuccess(response.data));
        } else {
          toast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getMonthlyRevenue = (data) => async (dispatch) => {
  try {
    await api.post(QTPET.getNannyMonthlyRevenue, data).then((response) => {
      if (response) {
        if (response.data.status) {
          dispatch(monthlyRevenueSuccess(response));
        } else {
          toast.error(response.error);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export default slice.reducer;
