/* eslint-disable no-unused-vars */

import Checkbox from "@mui/material/Checkbox";
import {
  Autocomplete,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { appColors } from "../../theme/colors";
import { useDispatch } from "react-redux";
import { addNewmember, addNewuser } from "../../store/slice/chat";
import AdminButton from "../adminButton";
import AlertDialog from "../dialog/alertDialog/alertDialog";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AdminInput from "../adminInput";
import Loader from "../loader/loader";
export default function AddMemberDialog({ ...props }) {
  const { open, handleClose, title, getChatListMember, bookingId, memberList } =
    props;
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState([]);
  const [addNewUser, setNewUser] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [formDataError, setFormDataError] = useState({});
  const [userList, setuserList] = useState([]);
  const [value, setValue] = useState("existUsers");
  useEffect(() => {
    if (getChatListMember?.length > 0) {
      getChatListMember?.map((data) => {
        let index = memberList?.findIndex(
          (item) => item.userId == data?.userId
        );
        let objIndex = userList?.findIndex(
          (item) => item.userId == data?.userId
        );
        console.log(index, "index > 0");
        if (index < 0 && objIndex < 0) {
          setuserList((pre) => [...pre, data]);
        }
      });
    }
  }, [getChatListMember]);
  console.log("memberList", memberList, userList);
  const handleuserIdArray = (array, newValue) => {
    let userId = Array.isArray(newValue)
      ? newValue.map((data) => data.userId)
      : [];

    setFormData((formData) => ({
      formData,
      userId: userId,
    }));
    setFormDataError((formDataError) => ({
      ...formDataError,
      userIds: "",
    }));
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleAgree = () => {
    const Param = {
      language: "en",
      newUserId: formData.userId,
      bookingId: bookingId,
      isRemove: false,
    };
    dispatch(addNewmember(Param));
    handleClose();
  };

  const handleAddUser = () => {
    if (!addNewUser.email) {
      setFormDataError((formError) => ({
        ...formError,
        email: "Please enter email",
      }));
      console.log(formDataError);
      return;
    }
  

    const Param = {
      bookingId: bookingId,
      email: addNewUser.email,
      shortDescription: "admin",
      language: "en",
    };
    dispatch(addNewuser(Param,setShowLoader));

  };
  const getAllUser = (val) => {
    const Param = {
      language: "en",
      newUserId: val,
      bookingId: bookingId,
      isRemove: false,
    };
  };
  const handleCloseConfirm = () => {
    setConfirmDialog(false);
  };
  const handleOpenConfirm = () => {
    if (!formData?.isAllUser && formData?.userId == 0) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        userIds: "Please select users",
      }));

      return;
    } else {
      setConfirmDialog(true);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ background: "	#FAF9F6" }}>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Select user
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={value}
                onChange={handleChange}
                name="radio-buttons-group"
                sx={{ display: "flow" }}
              >
                <FormControlLabel
                  value="existUsers"
                  control={<Radio />}
                  label="Existing user"
                />
                <FormControlLabel
                  value="newUser"
                  control={<Radio />}
                  label="New user"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {value == "existUsers" ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* ************************Here is Existing user list ***************** */}
                  <Autocomplete
                    sx={{
                      pt: 1,
                      "& .MuiChip-label": {
                        color: "black",
                      },
                      "& .MuiChip-deleteIcon": {
                        color: "black",
                      },
                      display: value !== "existUsers" ? "none" : "",
                    }}
                    multiple
                    id="checkboxes-tags-demo"
                    onChange={handleuserIdArray}
                    options={getChatListMember ? userList : []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <Typography sx={{ color: "black", pt: 0.8 }}>
                          {option.name}
                        </Typography>
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          getAllUser(e.target.value);
                          setFormDataError((formDataError) => ({
                            ...formDataError,
                            userIds: "",
                          }));
                        }}
                      />
                    )}
                  />
                  <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                    {formDataError.userIds ? formDataError.userIds : ""}
                  </Typography>
                </Grid>
              </Grid>

              <DialogActions>
                <AdminButton title="Add" onClick={handleOpenConfirm} />
                <Button
                  variant="Yes"
                  sx={{
                    mt: 1,
                    py: 1.3,
                    color: "white",
                    backgroundColor: "#299AD5",

                    ":hover": {
                      color: "white",
                      backgroundColor: "#5856d6",
                    },
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              {/* ***************************Add new user ************************ */}
              <Typography>
                <Typography variant="p" sx={{color:"grey"}}>
                  Enter an email to invite your friend to
                  join this chat.
                </Typography>
                <br></br>
                <br></br>
                <AdminInput
                  title="Enter email"
                  type="text"
                  value={addNewUser?.email ? addNewUser?.email : ""}
                  onChange={(e) => {
                    if (e) {
                      setNewUser((addNewUser) => ({
                        ...addNewUser,
                        email: e.target.value,
                      }));
                      if (e) {
                        setFormDataError((formError) => ({
                          ...formError,
                          email: "",
                        }));
                      }
                    }
                  }}
                  formError={formDataError.email}
                />
                <br></br>
               
              </Typography>
              <DialogActions>
                <AdminButton title="Add" onClick={()=>{
                  setConfirmDialog(true)
                }} />
                <Button
                  variant="Yes"
                  sx={{
                    mt: 1,
                    py: 1.3,
                    color: "white",
                    backgroundColor: "#299AD5",

                    ":hover": {
                      color: "white",
                      backgroundColor: "#5856d6",
                    },
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <Divider light={true} />
      {confirmDialog && (
        <AlertDialog
          title="Warning"
          text="Are you sure you want to Add this account in Group Chat."
          open={confirmDialog}
          handleClose={handleCloseConfirm}
          handleAgree={value==="existUsers"?handleAgree:handleAddUser}
        />
      )}
       {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </Dialog>

  );
}
