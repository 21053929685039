import { ThemeProvider, useTheme } from "@emotion/react";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";

import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { ReadNotication} from "../../../store";
import { appColors } from "../../../theme/colors";
import defaultImage from "../../../assets/img/default.jpg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { NotificationListData } = useSelector((state) => state.userSlice);
 const navigate=useNavigate()
  const handleNotification=(data)=>{
if( data?.isRead==true)
   if((data?.notificationType==14)||(data?.notificationType==20)||(data?.notificationType==17)||(data?.notificationType==23))
    navigate('/dashboard/reschedulenanny')
  else{
    navigate('/dashboard/bookings')
  }
if(data?.isRead==false)
    {const param={
        language:"en",
        notificationId:data?._id
    }
    dispatch(ReadNotication(param))

    if((data?.notificationType==14)||(data?.notificationType==20)||(data?.notificationType==17)||(data?.notificationType==23))
    navigate('/dashboard/reschedulenanny')
  else{
    navigate('/dashboard/bookings')
  }
  }
}
  console.log(NotificationListData);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Quote Request </title>
      </Helmet>
      <Box
        sx={{
          background: "white",
          mt: isMobile ? -2 : 0,
          p: 2,
          height: "100%",
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Notification</strong>
            </Typography>
          </Grid>
        </Grid>

        {NotificationListData?.data?.length > 0 ? (
          NotificationListData.data.map((item, index) => {
            console.log(item?._id,"id")
            return (
              <Card sx={{ boxShadow: 10, my: 2 , py:2, }} key={index}>
                <Grid container alignItems="center" spacing={0} sx={{ p: 1 }} onClick={()=>{handleNotification(item)}}>
                  <Grid
                    item
                    lg={1}
                    xs={3}
                    sx={{
                      marginRight: isMobile ? "0rem" : "-4rem",
                      overflow: "hidden",
                    }}
                  >
                  
                    <img
                      src={
                        item?.senderDetails?.profileImageUrl
                          ? item?.senderDetails?.profileImageUrl
                          : defaultImage
                      }
                      width={40}
                      height={40}
                      style={{ borderRadius: 50 }}
                      alt=""
                    />
                  </Grid>
                  <Grid item lg={11} xs={9}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Grid item xs={isMobile ? 12 : 9}>
                        <Typography variant="h6" sx={{ fontSize: 20, mb: 0.4 }}>
                          {item?.title}
                        </Typography>
                        <p style={{ margin: 0 ,fontSize:16}}>{item?.description}</p>
                      </Grid>
                      <Grid item xs={isMobile ? 12 : 3}>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            color: appColors.input,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "end",
                            width: "100%",
                          }}
                        >
                          {item?.createdAt
                            ? moment(item?.createdAt).format("MMMM DD")
                            : "N/A"}
                          <span></span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            );
          })
        ) : (
          <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              No notifications
            </Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
