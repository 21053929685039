// component
import { Icon } from "@iconify/react";

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: <Icon icon="humbleicons:dashboard" width={25} />,
  },
  {
    title: "Customers",
    path: "/dashboard/customer",
    icon: <Icon icon="la:users" width={25} />,
 
  },

  {
    title: "Delivery staff",
    path: "/dashboard/deliveryStaff",
    icon: <Icon icon="tdesign:undertake-delivery" width={25} />,
  },
  {
    title: "Quote request",
    path: "/dashboard/quoteRequest",
    icon: <Icon icon="bi:chat-quote" width={20} />,
  },
  {
    title: "Bookings",
    path: "/dashboard/bookings",
    icon: <Icon icon="material-symbols:orders-outline" width={25} />,
  },
  {
    title: "Payment History",
    path: "/dashboard/paymentHistory",
    icon: <Icon icon="material-symbols-light:payments-outline" width={28} />,
  },
  {
    title: "Announcements",
    path: "/dashboard/announcement",
    icon: <Icon icon="mingcute:announcement-line" width={28} />,
  },
  {
    title :"Promo Code",
    path : "/dashboard/promoCode",
    icon: <Icon icon="streamline:discount-percent-coupon" width={28} />,

  },
  {
    title :"Reschedule Bookings",
    path : "/dashboard/reschedulenanny",
    icon: <Icon icon="ri:calendar-schedule-line" width={28} />,

  }
];

export default navConfig;
