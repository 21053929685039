import React from "react";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function Spinner() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CircularProgress  />
      </Box>
    </>
  );
}
