import React, { useEffect, useState } from "react";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import ChatTabs from "./subcomponent/chatTabs";
// import MessageList from "./subcomponent/messageList";
// import MessageLayout from "./subcomponent/messageLayout";
// import ChatHistory from "./subcomponent/chatHistory/index";
import { getChatList } from "../../../store/slice/chat";
import { useDispatch } from "react-redux";
import Spinner from "../../../components/spinner";
// import secureLocalStorage from "react-secure-storage";

const Chat = () => {
  // const [callHistoryPage, setCallHistoryPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [viewMoreLoader, setViewMoreLoader] = useState(false);
  const [limit, setLimit] = useState(10);
  // const { messageListData } = useSelector((state) => state.chatSlice);

  // const loginStorage = secureLocalStorage.getItem(
  //   process.env.REACT_APP_USER_STORAGE_KEY
  // );
  // const loginData = JSON.parse(loginStorage);
  // const [itemData,setItemData] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
    getChatListing();
  }, []);

  // const handleTabs = (value) => {
  //   if (value === "chatHistory") {
  //     setCallHistoryPage(true);
  //   } else {
  //     setCallHistoryPage(false);
  //   }
  // };

  const getChatListing = (perPage, search, loader) => {
    setViewMoreLoader(!loader ? true : false);
    var data = {
      language: "en",
      page: "1",
      perPage: perPage ? perPage : limit,
      search: search,
      isCompleted: false,
    };
    dispatch(getChatList(data, setShowLoader, setViewMoreLoader));
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>QT PET - Chat </title>
        </Helmet>
      </ThemeProvider>

      <Grid container>
        <ChatTabs
          getChatListing={getChatListing}
          setLimit={setLimit}
          limit={limit}
          viewMoreLoader={viewMoreLoader}
        />
      </Grid>
      {showLoader ? (
        <>
          <Box
            style={{
              position: "absolute",
              top: "69%",
              left: "50%",
            }}
          >
            <Spinner />
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Chat;
