import { Avatar, Box, Card, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { StyledBadge } from "../../../../../theme/create-components";
import Messages from "./message";
import { socket } from "../../../../../helpers/socketio-client";
import InputEmoji from "react-input-emoji";
import { Icon } from "@iconify/react";
// import { appColors } from "../../../../../theme/colors";
import AddMemberDialog from "../../../../../components/newMemberDialog";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewMemberList } from "../../../../../store/slice/chat";
import AdminButton from "../../../../../components/adminButton";

const MessageLayout = (props) => {
  const { getChatListMember } = useSelector((state) => state.chatSlice);
  const { loginData, messageListData, itemData, chatHistory, getChatListing } =
    props;

  const [text, setText] = useState("");
  const [newMember, setNewMember] = useState(false);
  const dispatch = useDispatch();
  function handleOnEnter(value) {
    if (value.trim().length !== 0) {
      socket.emit("sendMessage", {
        userType: 1,
        bookingId: itemData?.bookingId,
        senderId: loginData?.userId,
        data: value,
        dataType: "text",
      });
    }
  }

  function handleClickEnter() {
    if (text.trim().length !== 0) {
      socket.emit("sendMessage", {
        userType: 1,
        bookingId: itemData?.bookingId,
        senderId: loginData?.userId,
        data: text,
        dataType: "text",
      });
      setText("");
    } else {
      console.log("Blank Message is not allowed");
    }
  }
  const handleNewMember = () => {
    let requestParam = {
      bookingId: itemData?.bookingId,
      language: "en",
      search: null,
      page: 1,
      perPage: 10,
    };
    dispatch(fetchNewMemberList(requestParam));
    setNewMember(true);
  };
  return (
    <>
      <Card
        variant="solid"
        sx={{
          py: 2,
          minHeight: "78.2vh !important",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                my: 1,
                alignItems: "center",
                py: 0.5,
                px: 2,
              }}
            >
              <Box sx={{ width: "45px", mr: 1 }}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    sx={{
                      // width: "100%",
                      // height: "100%",
                      borderRadius: "50%",
                      // objectFit: "cover",
                    }}
                    alt="Cindy Baker"
                    src={itemData?.groupIcon}
                  />
                </StyledBadge>
              </Box>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {itemData?.chatName}
                </Typography>
                <Typography sx={{ color: "gray" }}>
                  {itemData?.groupMember?.map((item, index) => {
                    //    console.log("group member data ",item)
                    // console.log("group member list ",item?.name)
                    return (index ? ", " : "") + item?.name;
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
          <AdminButton
            title="Add"
            startIcon={
              <Icon icon="gridicons:user-add" style={{ fontSize: 32 }} />
            }
            onClick={handleNewMember}
            sx={{ minWidth: 100 }}
          />
        </Box>

        <Divider />
        <Box style={{ height: "62vh", overflow: "auto" }}>
          <Messages
            itemData={itemData}
            messageListData={messageListData}
            loginData={loginData}
            getChatListing={getChatListing}
          />
        </Box>
        {chatHistory === "chatHistory" ? (
          <></>
        ) : (
          <Grid container sx={{display: "flex", alignItems: "center"}} spacing={2}>
            <Grid
              item
              xs={11}
              sx={{  px: 2, width:"100vw"}}
            >
              <InputEmoji
                value={text}
                onChange={(val) => {
                  setText(val);
                }}
                cleanOnEnter
                onEnter={(e) => handleOnEnter(e)}
                placeholder="Type a message "
                style={{}}
              />

            
            </Grid>
            <Grid item xs={1}>
            <Icon
                icon="material-symbols:send"
                style={{ fontSize: 30, cursor: "pointer" }}
                onClick={handleClickEnter}
                cleanOnClick
              />
            </Grid>
          </Grid>
        )}
      </Card>
      {newMember && (
        <AddMemberDialog
          open={newMember}
          handleClose={() => {
            setNewMember(false);
          }}
          memberList={itemData?.groupMember}
          getChatListMember={getChatListMember}
          title="Add New member"
          bookingId={itemData?.bookingId}
        />
      )}
    </>
  );
};
export default React.memo(MessageLayout);
