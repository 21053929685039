/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  // Icon,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { getPaymentHistoryList } from "../../../store";
import Loader from "../../../components/loader/loader";
import AdminInput from "../../../components/adminInput";
import moment from "moment/moment";
import AdminDatePicker from "../../../components/adminDatePicker";
import AdminSelect from "../../../components/adminSelect";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

export default function index() {
  var checkDate = new Date();
  var newDate = moment(checkDate).format("YYYY-MM-DD");
  var page = 1;
  var perPage = 10;
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [showLoader, setShowLoader] = useState(false);
  const [pagecheck, setPageCheck] = useState(1);
  const [statusValue, setStatusValue] = useState("");
  // const [date, setDate] = useState({});
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");

  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const { totalCount, isLoading, paymentHistory } = useSelector(
    (state) => state.userSlice
  );

  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));

  const dispatch = useDispatch();

  useEffect(() => {
    setShowLoader(true);
    paymentHistoryList();
  }, []);

  useEffect(() => {
    if (paymentHistory?.length > 0) {
      setShowLoader(false);
    }
  }, [paymentHistory]);

  const handleStartDate = (value) => {
    const valDate = moment(value).format("MM/DD/YYYY");
    setStartDate(value);
    setStartDateForSend(valDate);
  };

  const handleEndDate = (value, numberOfRows) => {
    console.log("value", value);
    const valDate = moment(value).format("MM/DD/YYYY");
    console.log(valDate, "valDate");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    setEndDateForSend(valDate === "Invalid date" ? "" : valDate);

    var data = {
      language: "en",
      bookingStatus: statusValue,
      status: "",
      startDate:
        valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
      endDate: valDate !== "Invalid  date" ? valDate : "",
      search: searchValue,
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getPaymentHistoryList(data, isSearch, setShowLoader));
  };

  const paymentHistoryList = (page, numberOfRows, search) => {
    var data = {
      language: "en",
   
      bookingStatus: statusValue,
      status: "",
      startDate: "",
      endDate: "",
      search: search,
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
    };
    let isSearch = search ? true : false;
    dispatch(getPaymentHistoryList(data, isSearch, setShowLoader));
  };

  const dateData = [
    { id: "day", label: "Day" },
    { id: "week", label: "Week" },
    { id: "month", label: "Month" },
    { id: "lastDay", label: "Last Day" },
    { id: "lastWeek", label: "Last Week" },
    { id: "lastMonth", label: "Last Month" },
  ];

  const statusData = [
    { id: 0, label: "New order" },
    { id: 1, label: "Completed" },
    { id: 2, label: "On going" },
    { id: 3, label: "Cancel" },
  ];

  useEffect(() => {
    paymentHistoryList();
  }, [statusValue]);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;

        paymentHistoryList(page, "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        paymentHistoryList(page, "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      paymentHistoryList(page, numberOfRows, "");
    },
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 1) {
      paymentHistoryList(page, perPageRows, e.target.value);
    } else if (e.target.value.length === 0) {
      paymentHistoryList(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    paymentHistoryList(page, perPage, "");
  };
  const columns = [
    {
      name: "txnId",
      label: "Transaction Id",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value ? value : "-"}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "bookingData",
      label: "Order Number",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value?.bookingNumber ? value?.bookingNumber : "-"}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "paymentHistoryData",
      label: "Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {moment(value?.dateTime).format("MM/DD/yyyy")}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "bookingData",
      label: "Buyer",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip
                title={`${value?.buyerFirstName} ${value?.buyerLastName}`}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value?.buyerFirstName || value?.buyerLastName
                    ? `${value?.buyerFirstName} ${value?.buyerLastName}`
                    : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "bookingData",
      label: "Seller",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip
                title={`${value?.sellerFirstName} ${value?.sellerLastName}`}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value?.sellerFirstName || value?.sellerLastName
                    ? `${value?.sellerFirstName} ${value?.sellerLastName}`
                    : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },

   
    {
      name: "deliveryData",
      label: "Delivered Airpot",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value.airpotName ? value.airpotName : "-"}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "bookingData",
      label: "Full Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
 
          if (value?.bookingType==2)
          {

          return (
            <Grid>
              <Typography>
                {value?.paymentAmount }
              </Typography>
            </Grid>
          );
          }
          else {
            return (
              <Grid>
                <Typography>-</Typography>
              </Grid>
            );
          }
    
        },
      },
    },
    {
      name: "bookingData",
      label: "Deposit Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value?.bookingType==1)
          {

          return (
            <Grid>
              <Typography>
                {value?.payDepositAmount }
              </Typography>
            </Grid>
          );
          }
          else {
            return (
              <Grid>
                <Typography>-</Typography>
              </Grid>
            );
          }
        },
      },
    },
    {
      name: "bookingData",
      label: "Payment Status",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            // <Grid>
              
            //     {value?.bookingType == 1 ? <Typography sx={{backfaceVisibility:"red"}}>Pay Deposit</Typography> : <Typography sx={{background:"green"}}>Full Paid</Typography>}
           
            // </Grid>
            <Grid>
         
              <Chip
                label={value?.bookingType == 1? "Pay Deposit" : "Full Paid"}
                sx={{
                  backgroundColor:value?.bookingType == 1?  "#e0f7ea" : "#ffe9e9",
                  color: value?.bookingType == 1?  "red" : "green",
                }}
              />
         
          </Grid>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Payment History</title>
      </Helmet>
      <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Payment History</strong>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  paymentHistoryList(page);
                }
              }}
              title="Search by name"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{ cursor: "pointer" }}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              title="Select start date"
              max={endDate != "dd/mm/yyyy" ? endDate : newDate}
              onKeyDown={(e) => e.preventDefault()}
              type="date"
              onChange={(e) => handleStartDate(e.target.value)}
              value={startDate || ""}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              title="Select end date"
              type={"date"}
            onKeyDown={(e) => e.preventDefault()}
            max={endDate != "dd/mm/yyyy" ? endDate : newDate}
            onChange={(e) => handleEndDate(e.target.value)}
            value={endDate || ""}
            />
          </Grid>
        </Grid>
      </Box>

      <div className="App wrapper">
        {isLoading ? (
          <>
            <Grid
              container
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                mt: 2,
                height: 300,
                backgroundColor: "white",
              }}
            >
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            {paymentHistory && paymentHistory.length > 0 ? (
              <Grid container sx={{ width: "auto" }}>
                <Grid xs={12}>
                  <MUIDataTable
                    data={paymentHistory}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
               { !showLoader &&  <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 300,
                    backgroundColor: "white",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>}
              </>
            )}
          </>
        )}
      </div>
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
}
