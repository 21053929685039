import { Helmet } from "react-helmet-async";
// @mui
import { ThemeProvider, useMediaQuery } from "@mui/material";
// components

import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDashboardData } from "../../../store/slice/users";
import DeliveryDashboard from "../deliveryStaff/subcomponents/deliveryDashboard";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import AdminInput from "../../../components/adminInput";
import moment from "moment";
import Loader from "../../../components/loader/loader";

// sections

// ----------------------------------------------------------------------
export default function AdminDashboard() {
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const { dashboardData } = useSelector((state) => state.userSlice);
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [showLoader, setShowLoader] = useState(false);
  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");
  const dispatch = useDispatch();
  var checkDate = new Date();
  var newDate = moment(checkDate).format("YYYY-MM-DD");

  useEffect(() => {
    getDashboard();
  }, []);

  const handleStartDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setStartDate(value);
    setStartDateForSend(valDate);
  };
  const handleEndDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");

    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    setEndDateForSend(valDate === "Invalid date" ? "" : valDate);

    var data = {
      language: "en",
      startDate:
        valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
      endDate: valDate !== "Invalid  date" ? valDate : "",
    };
    dispatch(getDashboardData(data, setShowLoader));
  };
  const getDashboard = () => {
    endDateForSend === "Invalid date" && setEndDateForSend("dd/mm/yyyy");
    var data = {
      language: "en",

      startDate: "",
      endDate: "",
    };
    dispatch(getDashboardData(data, setShowLoader));
  };
  const dashboardDataConfig = [
    {
      color: "#2554fb",
      backgroundColor: "#e1e7fc",
      icon: "mdi:users",
      title: "Total Pet Owner",
      count: dashboardData?.totalUsers ? dashboardData.totalUsers : 0,
    },
    {
      color: "#000",
      backgroundColor: "#ffd079",
      icon: "healthicons:money-bag-outline",
      title: " Total Revenue ",
      count: dashboardData?.totalRevenue
        ? `$ ${dashboardData.totalRevenue}`
        : 0,
    },
    {
      color: "#03ad4f",
      backgroundColor: "#9edfcbbd",
      icon: "ph:seal-check-fill",
      title: " Completed Bookings",
      count: dashboardData?.totalBooking ? dashboardData.totalBooking : 0,
    },
    {
      color: "#000",
      backgroundColor: "#bdf9f9",
      icon: "mdi:user",
      title: " Total Breeder",
      count: dashboardData?.totalBreeder ? dashboardData.totalBreeder : 0,
    },
    {
      color: "#2554fb",
      backgroundColor: "#e1e7fc",
      icon: "ph:users-three",
      title: " Total delivery staff",
      count: dashboardData?.totalNanny ? dashboardData.totalNanny : 0,
    },
    {
      color: "#03ad4f",
      backgroundColor: "#e0f7ea",
      icon: "tabler:user-check",
      title: "Active account",
      count: dashboardData?.activeNanny ? dashboardData.activeNanny : 0,
    },
    {
      color: "#fb2727",
      backgroundColor: "#ffe9e9",
      icon: "iconoir:user-xmark",
      title: "Suspended Nanny",
      count: dashboardData?.suspendedNanny ? dashboardData.suspendedNanny : 0,
    },
    {
      color: "darksalmon",
      backgroundColor: "#e9967a52",
      icon: "solar:user-cross-bold",
      title: "Suspended Users",
      count: dashboardData?.suspendedUsers ? dashboardData.suspendedUsers : 0,
    },
    // {
    //   color: "#fb2727",
    //   backgroundColor: "#ffe9e9",
    //   icon: "iconoir:user-xmark",
    //   title: "Rejected account",
    //   count: dashboardData?.rejectedNanny ? dashboardData.rejectedNanny : 0,
    // },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Dashboard </title>
      </Helmet>
      <Grid container spacing={2} sx={{ my: 3 }}>
        <Grid item xs={isMobile ? 12 : 2.8}>
          <AdminInput
            title="Start Date"
            max={endDate != "dd/mm/yyyy" ? endDate : newDate}
            onKeyDown={(e) => e.preventDefault()}
            type="date"
            onChange={(e) => handleStartDate(e.target.value)}
            value={startDate || ""}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 2.8}>
          <AdminInput
            title="End date"
            type={"date"}
            onKeyDown={(e) => e.preventDefault()}
            max={endDate != "dd/mm/yyyy" ? endDate : newDate}
            onChange={(e) => handleEndDate(e.target.value)}
            value={endDate || ""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        {dashboardDataConfig?.map((item, i) => (
          <Grid item xs={12} md={3} key={i} sx={{ m: isMobile ? 1 : 0 }}>
            <DeliveryDashboard
              color={item.color}
              backgroundColor={item.backgroundColor}
              icon={item.icon}
              title={item.title}
              count={item.count}
            />
          </Grid>
        ))}
      </Grid>
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
}
