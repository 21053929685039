import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
// import secureLocalStorage from "react-secure-storage";
// import { color } from "../../theme/color";
import Video from "../../assets/img/splash.mp4";
import { Link } from "@mui/material";
import theme from "../../theme";
import { appColors } from "../../theme/colors";
import { forgotPasswordAdmin } from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.

// const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formError, setFormError] = useState({});
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter your email address",
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }
    var data = {
      email: email,
      userType: 1,
    };
    dispatch(forgotPasswordAdmin(data, navigate));

    // secureLocalStorage.setItem("authenticated", true);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{
            backgroundColor: appColors.main,
            height: "100vh",
            padding: { xs: false, sm: false, md: 10, lg: 10 },
            mt: -5,
          }}
        >
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={4}
            md={5}
            sx={{
              background: "black",
              borderRadius: 1,
              height: "85vh",
            }}
          >
            <video
              loop
              playsInline
              autoPlay
              muted
              id="video"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                // filter: "brightness(0.7)",
                borderRadius: 1,
              }}
            >
              <source src={Video} type="video/mp4" />
            </video>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            component={Paper}
            square
            sx={{ borderRadius: 1, height: "85vh" }}
          >
            {/* <Logo /> */}
            <Box
              sx={{
                my: 6,
                mx: 15,
                //   display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: appColors.main,
                  width: 50,
                  height: 50,
                  marginLeft: 19,
                }}
              >
                <Icon icon="fluent-mdl2:signin" />
              </Avatar>

              <Typography sx={{ textAlign: "center", fontSize: 21 }}>
                Forgot Password
              </Typography>
              <Box noValidate sx={{ mt: 1 }}>
                <AdminInput
                  title="Email Address"
                  type="email"
                  value={email}
                  onChange={(val) => {
                    if (val) {
                      setEmail(val.target.value);
                      setFormError((formError) => ({
                        ...formError,
                        email: "",
                      }));
                    }
                  }}
                  formError={formError.email}
                />
                <Link
                  sx={{
                    float: "right",
                    textDecoration: "none",
                    fontSize: 15,
                    fontWeight: 600,
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                  href="login"
                >
                  Back To Login
                </Link>
                <AdminButton
                  onClick={handleSubmit}
                  title={"Reset Password"}
                  fullWidth="true"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
