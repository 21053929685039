/* eslint-disable no-unused-vars */
import {
  OutlinedInput,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useEffect, useRef, useState } from "react";
import { DialogTitle } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  reassignBookingtoNanny,
  selectNanny,
} from "../../../store/slice/users";
import AdminInput from "../../adminInput";
import AlertDialog from "../alertDialog/alertDialog";

export default function AssignAnotherNanny({ ...props }) {
  const { open, handleClose, assignData } = props;
  const { NannyList } = useSelector((state) => state.userSlice);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const { getRecordingData } = useSelector((state) => state.chatSlice);
  console.log("getRecordingData", getRecordingData);
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));
  // console.log("Booking Assign", assignData);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllNanny();
  }, []);

  const getAllNanny = () => {
    let data = {
      language: "en",
      searchQuery: "",
    };
    dispatch(selectNanny(data));
  };

  const handleSubmit = () => {
    if (nannyId == 0) {
      setFormError((formError) => ({
        ...formError,
        user: "Please select user",
      }));
      return;
    }
    if (!formData?.note) {
      setFormError((formError) => ({
        ...formError,
        note: "Please enter note",
      }));
      return;
    }

    handleAgree();
    setConfirmDialog(true);
    setFormError({
      user: "",
      note:'',
    
    });
    setFormData({
      user: "",
      note:'',
    
    });
  };
  const theme = useTheme();
  const [nannyId, setNannyId] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const handleAgree=()=>{
  console.log("formdata",formData?.note)
  let data = {
    language: "en",
    nannyId:nannyId,
    bookingId: assignData?._id,
    note: formData?.note,
  };
  console.log(data,"datas")
  dispatch(reassignBookingtoNanny(data));
  handleClose();
}
  const handleChange = (nannyId) => {
    setNannyId(nannyId);
  };
  return (
    <>
      <Dialog maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle>Other Nanny</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormControl sx={{ m: 1, width: 520 }}>
            <InputLabel id="demo-multiple-name-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={nannyId}
              onChange={(event) => handleChange(event?.target?.value)}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {NannyList?.data?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
              {formError ? formError?.user : ""}
            </Typography>
          </FormControl>
          <Box sx={{ px: 1, mt: 2 }}>
            <AdminInput
              placeholder={"Write note "}
              value={formData?.note}
              onChange={(e) => {
                console.log(formData?.note)
                setFormData((formData) => ({
                  ...formData,
                  note: e.target.value,
                }));
              }}
              formError={formError?.note}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="Yes"
            sx={{
              mt: 1,
              mb: 2,
              color: "white",
              backgroundColor: "#299AD5",
              height: 40,
              ":hover": {
                color: "white",
                backgroundColor: "#5856d6",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="Yes"
            sx={{
              mt: 1,
              mb: 2,
              color: "white",
              backgroundColor: "#299AD5",
              height: 40,
              ":hover": {
                color: "white",
                backgroundColor: "#5856d6",
              },
            }}
            onClick={handleSubmit}
          >
            Assign
          </Button>
        </DialogActions>

        {confirmDialog && (
          <AlertDialog
            open={confirmDialog}
            text="Are you sure to approve these flight details "
            handleClose={()=>{setConfirmDialog(false)}}
            handleAgree={handleAgree}
            sx={{ fontSize: 16 }}
          />
        )}
      </Dialog>
    </>
  );
}
