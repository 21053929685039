import React, { useEffect, useState } from "react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getChatHistoryList } from "../../../../../store/slice/chat";
import Spinner from "../../../../../components/spinner";
import { Box } from "@mui/material";
import MessageLayout from "../messageLayout";
import MessageList from "../messageList";
import secureLocalStorage from "react-secure-storage";

export default function index(props) {
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));
  const { handleLastMessage, itemData } = props;
  const dispatch = useDispatch();
  const { chatListData, messageListData } = useSelector(
    (state) => state.chatSlice
  );
  const [showLoader, setShowLoader] = useState(false);
  const loginStorage = secureLocalStorage.getItem(
    process.env.REACT_APP_USER_STORAGE_KEY
  );
  const loginData = JSON.parse(loginStorage);
  useEffect(() => {
    getChatHistoryListing();
  }, []);

  useEffect(() => {
    console.log("chatListData == ", chatListData);
    let index = chatListData?.data?.findIndex(
      (item) => item?._id === itemData?._id
    )
      ? chatListData?.data?.findIndex((item) => item?._id === itemData?._id)
      : 0;
    console.log("indexML", index);
    if (chatListData?.length > 0 ) {
      handleLastMessage(chatListData[index === -1 ? 0 : index]);
    }
  }, [chatListData]);

  const getChatHistoryListing = () => {
    var data = {
      language: "en",
      page: "1",
      perPage: "100",
      search: "",
      isCompleted: true,
    };
    dispatch(getChatHistoryList(data, setShowLoader));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <MessageList
            chatListData={chatListData}
            chatHistory={"chatHistory"}
            itemData={itemData}
            handleLastMessage={handleLastMessage}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 8}>
          <MessageLayout
            chatHistory={"chatHistory"}
            messageListData={messageListData}
            loginData={loginData}
            itemData={itemData}
          />
        </Grid>
      </Grid>

      {showLoader ? (
        <>
          <Box
            style={{
              position: "absolute",
              top: "69%",
              left: "50%",
            }}
          >
            <Spinner />
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
