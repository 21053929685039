import { QTPET } from "../../services/apiConstant";
import api from "../../services";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "promoCodeSlice",
  initialState: {
    promoCode: null,
    promoCodeList: [],
    promoCodeDetails: null,
    deletePromoCode: null,
  },

  reducers: {
    addPromoCodeSuccess: (state, action) => {
      state.promoCode = action.payload;
    },
    getPromoCodeListSuccess: (state, action) => {
      state.promoCodeList = action.payload;
    },
    getPromoCodeDetailsSuccess: (state, action) => {
      state.promoCodeDetails = action.payload.data.data;
    },
    deletPromoCodeSucces: (state, action) => {
      state.promoCodeList.data = state.promoCodeList.data.filter(
        (item) => item._id != action.payload.data._id
      );
      state.promoCodeList.totalCount= state.promoCodeList.totalCount-1
    },
  },
});

const {
  addPromoCodeSuccess,
  getPromoCodeListSuccess,
  deletPromoCodeSucces,
  getPromoCodeDetailsSuccess} = slice.actions;

// Add promocode

export const addPromoCode = (data,setShowLoader,navigate) => async (dispatch) => {
  setShowLoader(true)
  try {
    await api.post(QTPET.addPromoCode, data).then((response) => {
      if (response) {
        dispatch(addPromoCodeSuccess(response));
        toast.success(response.data.message)
      }
      navigate("/dashboard/promoCode")
      setShowLoader(false)

    });
  } catch (e) {
    return console.error(e);
  }
};

// getPromoCodeList

export const getPromoCodeList = (page, perPage,search,setShowLoader) => async (dispatch) => {
  setShowLoader(true)
  try {
    await api
      .get(
        `${QTPET.getPromoCodeList}?language=en&page=${page}&perPage=${perPage}&search=${search}`
      )
      .then((response) => {
        if (response) {
          dispatch(getPromoCodeListSuccess(response.data));
        }
      });
      setShowLoader(false)

  } catch (e) {
    return console.error(e);
  }
};

export const deletPromoCode = (data,setShowLoader) => async (dispatch) => {
  setShowLoader(true)
  try {
    await api
      .delete(
        `${QTPET.deletePromoCode}?language=${data?.language}&codeId=${data?.codeId}`
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(deletPromoCodeSucces(response.data));
        }
      });
      setShowLoader(false)

  } catch (e) {
    return console.error(e);
  }
};

export const getPromoCodeDetails = (data) => async (dispatch) => {
  try {
    await api
      .get(
        `${QTPET.getPromoCodeDetails}?language=${data?.language}&codeId=${data?.codeId}
      `
      )
      .then((response) => {
        if (response) {
          dispatch(getPromoCodeDetailsSuccess(response));
        }
      });
  } catch (e) {
    return console.error(e);
  }
};

export default slice.reducer;
