import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../../theme";
import { ThemeProvider, Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import DeliveryDashboard from "./deliveryDashboard";
import AdminInput from "../../../../components/adminInput";
import { getMonthlyRevenue } from "../../../../store/slice/users";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";
import MUIDataTable from "mui-datatables";
// import { Icon } from "@iconify/react";
import moment from "moment";
export default function MonthlyDelivery() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const d = new Date();
    monthlyRevenue(d);
  }, []);
  const monthlyRevenue = (defaultdate) => {
    const param = {
      language: "en",
      date: date ? date : defaultdate,
      userId: id,
      page: "1",
      perPage: "10",
    };
    dispatch(getMonthlyRevenue(param));
  };
  const { getMonthlyRevenueData, totalCount } = useSelector(
    (state) => state.userSlice
  );
  const [date, setDate] = useState();

  const dashboardDataConfig = [
    {
      color: "#2554fb",
      backgroundColor: "#e1e7fc",
      icon: "ph:users-three",
      title: "Total Delivery ",
      count: getMonthlyRevenueData?.totalCount
        ? getMonthlyRevenueData?.totalCount
        : 0,
    },
    {
      color: "#000",
      backgroundColor: "#ddddde",
      icon: "basil:user-clock-outline",
      title: " Total Revenue",
      count: getMonthlyRevenueData?.totalRevenue
        ? getMonthlyRevenueData?.totalRevenue
        : 0,
    },
  ];
  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    // onColumnSortChange: (changedColumn, direction) => {
    //   let sortingOrder = direction === "asc" ? 1 : -1;
    //   setSortOrder(sortingOrder);
    //   setSortColumn(changedColumn);
    //   customerList(page, "", changedColumn, sortingOrder);
    // },
    // onChangePage(currentPage) {
    //   if (currentPage >= pageCheck) {
    //     if (currentPage === 0) {
    //       currentPage = currentPage + 1;
    //     }
    //     setPageCheck(currentPage);
    //     page = currentPage + 1;
    //     customerList(page, "", "", "");
    //   } else {
    //     if (currentPage > 0) {
    //       page = currentPage - 1;
    //     }

    //     setPageCheck(currentPage);
    //     customerList(page, "", "", "");
    //   }
    // },
    // onChangeRowsPerPage(numberOfRows) {
    //   setNumberOfRows(numberOfRows);
    //   customerList(page, numberOfRows, "", "");
    // },
  };
  const columns = [
    {
      name: "bookingNumber",
      label: "Booking Id",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "sellerFirstName",
      label: "Seller",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value,tableMeta) => {
          console.log("seller name :",getMonthlyRevenueData[tableMeta.row])
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                         {value
                    ? getMonthlyRevenueData?.data[tableMeta.rowIndex].sellerFirstName +
                      " " +
                      getMonthlyRevenueData?.data[tableMeta.rowIndex].sellerLastName
                    : ""}
               
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "petFirstName",
      label: "Pet Owner ",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value,tableMeta) => {
        
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                {value
                    ? getMonthlyRevenueData?.data[tableMeta.rowIndex].petFirstName +
                      " " +
                      getMonthlyRevenueData?.data[tableMeta.rowIndex].petLastName
                    : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "breed",
      label: "Breed",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
        
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "00"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "pickAirpot",
      label: "Pickup airport",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value.airpotName}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "deliveredAirpot",
      label: "delivered airport",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value.airpotName}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "deliveryDate",
      label: "Delivered Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {moment(value ? value : "-").format("MM/DD/YYYY")}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "grandTotal",
      label: "Amount",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
              <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "00"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },

  
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>QT PET - Delivery Staff </title>
        </Helmet>
        <Box>
          <Grid container sx={{ justifyContent: "space-between", pb: 2 }}>
            <Grid sx={{ mt: 1 }}>
              <Typography variant="body1" sx={{ fontSize: 18 }}>
                <strong>Monthly Delivery</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={5}>
          {dashboardDataConfig?.map((item, i) => (
            <Grid item xs={12} md={3.5} key={i}>
              <DeliveryDashboard
                color={item.color}
                backgroundColor={item.backgroundColor}
                icon={item.icon}
                title={item.title}
                count={item.count}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={5} sx={{ p: 2, pt: 3 }} justifyContent={"end"}>
          <Grid item>
            <AdminInput
              type={"date"}
              title={"Start Date"}
              onChange={(e) => {
                setDate(e.target.value), monthlyRevenue();
              }}
            />
          </Grid>
        </Grid>

        <Grid container width={"100%"}>
          {getMonthlyRevenueData && getMonthlyRevenueData?.data?.length > 0 ? (
            <Grid item xs={12}>
              <Card sx={{ mt: 2 }}>
                <MUIDataTable
                  data={getMonthlyRevenueData?.data}
                  columns={columns}
                  options={options}
                />
              </Card>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              sx={{ mt: 2, height: 300, backgroundColor: "white" }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  No Records Found
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </>
  );
}
