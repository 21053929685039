import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import "./index.css";

export default function AdminSelect(props) {
  const { title, menuData, formError, value, onChange } = props;
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={title}
          onChange={(e) => onChange(e.target.value)}
        >
          {menuData &&
            menuData.map((item, i) => {
              return (
                <MenuItem value={item.id} key={i}>
                  {item.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
