import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  ThemeProvider,
  Grid,
  Badge,
} from "@mui/material";
// mocks_
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Icon } from "@iconify/react";
// import { color } from "../../../theme/color";
import theme from "../../../theme";
import { appColors } from "../../../theme/colors";
import ChangePassword from "../../../components/dialog/changePassword";
import { useDispatch, useSelector } from "react-redux";
import {  UnreadReadNotification, getNoticationList } from "../../../store";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  // const { loginData } = useSelector((state) => state.authSlice);
  const [showDialog, setShowDialog] = useState(false);
  const { UnReadCount } = useSelector((state) => state.userSlice);
  const loginStorage = secureLocalStorage.getItem(
    process.env.REACT_APP_USER_STORAGE_KEY
  );
  const dispatch = useDispatch()
  useEffect(() => {
    fetchNotificationList();
    Unread();
  }, []);
console.log("notification",UnReadCount)
  const Unread=()=>{
     const param={
      language:"en"
     }
    dispatch(UnreadReadNotification(param))
  }
  const fetchNotificationList = () => {
    const param = {
      language: "en",
      page: 1,
      perPage: 10,
    };
    dispatch(getNoticationList(param));
  };
  const loginData = JSON.parse(loginStorage);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    secureLocalStorage.clear();
    navigate("/login");
  };

  const handleProfile = () => {
    setShowDialog(true);
    setOpen(null);
  };
  return (
    <>
      <ChangePassword open={showDialog} handleClose={setShowDialog} />
      <ThemeProvider theme={theme}>
        <Grid container display={"flex"} justifyContent={"right"} spacing={2}>
          <Grid item>
            <Grid sx={{ mt: 0.9, mr: 0 }}>
              <Badge badgeContent={UnReadCount?.count} color={"error"}>
                <Icon
                  icon="mdi:bell-outline"
                  width="34"
                  height="34"
                  style={{ cursor: "pointer", color: appColors }}
                  onClick={() => navigate("/dashboard/notification")}
                />
              </Badge>
            </Grid>
          </Grid>
          <Grid item>
            <Grid sx={{ mt: 0.9, mr: 0 }}>
              <Icon
                icon="fluent:chat-12-regular"
                width="34"
                height="34"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard/chat")}
              />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleOpen}
              sx={{
                p: 0,
                ...(open && {
                  "&:before": {
                    zIndex: 1,
                    content: "''",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  },
                }),
              }}
            >
              <Avatar sx={{ backgroundColor: "white" }} alt="photoURL">
                <Icon
                  icon="iconamoon:profile-fill"
                  color={appColors.topbar}
                  width="34"
                />
              </Avatar>
            </IconButton>

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              slotProps={{
                sx: {
                  p: 2,
                  mt: 1.5,
                  ml: 0.75,
                  width: 250,
                  "& .MuiMenuItem-root": {
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  component="h1"
                  variant="h6"
                >
                  {loginData?.firstName?.toUpperCase()}
                </Typography>
                <Typography>{loginData?.email}</Typography>
              </Box>
              <Divider sx={{ borderColor: "#caced3", py: 0.5 }} />

              <MenuItem onClick={handleProfile} sx={{ pt: 1 }}>
                <Icon icon="formkit:password" color={"blue"} width="30" />
                &nbsp;&nbsp;&nbsp;
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: 1.57,
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  Change Password
                </Typography>
              </MenuItem>

              <Divider sx={{ borderColor: "#caced3", py: 0.1 }} />

              <MenuItem onClick={handleLogout}>
                <Icon
                  icon="ant-design:logout-outlined"
                  color="red"
                  width="30"
                />
                &nbsp;&nbsp;&nbsp;{" "}
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: 1.57,
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Popover>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
