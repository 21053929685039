/* eslint-disable no-unused-vars */
import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Card,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AdminInput from "../../../../components/adminInput";
import Logo from "../../../../assets/img/default.jpg";
import { StyledBadge } from "../../../../theme/create-components";
import { Badge } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { appColors } from "../../../../theme/colors";
import { useSelector } from "react-redux";
import AdminButton from "../../../../components/adminButton";
import Spinner from "../../../../components/spinner";

const MessageList = (props) => {
  const {
    chatListData,
    handleLastMessage,
    itemData,
    setLimit,
    limit,
    getChatListing,
    viewMoreLoader,
  } = props;
  const { chatListTotalCount } = useSelector((state) => state.chatSlice);
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("lg"));
  const ChatListDataComponent = (props) => {
    const { item } = props;
    return (
      <>
        <Box
          key={item}
          sx={{
            display: "flex",
            width: "100%",
            my: 1,
            alignItems: "center",
            py: 0.5,
            px: 2,
            cursor: "pointer",
            background: itemData?._id === item?._id ? appColors.topbar : "",
          }}
          onClick={() => handleLastMessage(item)}
        >
          <Box
            sx={{
              width: "35px",
              mr: 1,
            }}
          >
            <Badge color="green" variant="dot">
              <Avatar
                sx={{
                  width: 35,
                  height: 35,
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                alt="Cindy Baker"
                src={item?.groupIcon ? item?.groupIcon : Logo}
              />
            </Badge>
          </Box>
          <Grid container>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 800,
                    color: itemData?._id === item?._id ? "white" : "black",
                  }}
                >
                  {item?.chatName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 10,
                    width: 210,
                    color: itemData?._id === item?._id ? "white" : "grey",
                  }}
                >
                  {item?.lastMessage?.data}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
              <Badge
                badgeContent={item?.unreadCount}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    fontWeight: 600,
                    backgroundColor: appColors.topbar,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  return (
    <Card
      variant="solid"
      sx={{
        height: isMobile ? "90vh" : "78.4vh",
      }}
    >
      {/* <Box sx={{ my: 1 }}>
        <Typography variant="subtitle2" sx={{ fontSize: 20, opacity: 0.72 }}>
          Chats
        </Typography>
      </Box> */}

      <Box sx={{ px: 2, py: 2, position: "sticky" }}>
        <AdminInput
          placeholder="Search here..."
          onChange={(event) => getChatListing("", event.target.value)}
          startIcon={<Icon icon="clarity:search-line" />}
        />
      </Box>
      <Box
        sx={{
          height: isMobile ? "85vh" : "75.4vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          paddingBottom: "5rem",
        }}
      >
        <Grid>
          <Grid item>
            {chatListData && chatListData?.data
              ? chatListData?.data?.map((item) => {
                  return (
                    <>
                      <ChatListDataComponent item={item} />
                    </>
                  );
                })
              : chatListData &&
                chatListData?.map((item) => {
                  return (
                    <>
                      <ChatListDataComponent item={item} />
                    </>
                  );
                })}
            {!chatListData && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
                  No Data Found
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {chatListTotalCount > limit && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {viewMoreLoader ? (
              <Spinner />
            ) : (
              <AdminButton
                title="View More"
                onClick={() => {
                  let perPage = limit + 10;
                  setLimit(perPage);
                  getChatListing(perPage);
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};
export default React.memo(MessageList);
