import { Button } from "@mui/material";
import React from "react";

export default function AdminButton(props) {
  const { fullWidth, title, onClick,backgroundColor,hoverColor,hoverBackgroundColor,sx,startIcon,endIcon } = props;
  return (
    <Button
      fullWidth={fullWidth}
      type="submit"
      sx={{
        mt: 3,
        mb: 2,
        backgroundColor: backgroundColor?backgroundColor:"#3762ea",
        color: "white",
        height: 45,
        fontWeight: 700,
        ":hover": {
          color: hoverColor?hoverColor:"white",
          backgroundColor: hoverBackgroundColor?hoverBackgroundColor:"#2f53c7",
        },
        letterSpacing: 0.6,
        ...sx
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
