import { Grid, Typography } from "@mui/material";
import React from "react";

export default function faqs() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }} >
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>FAQS </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          1. How does the process work when using a pet flight nanny?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
          Our pet flight nanny service involves a dedicated professional
          accompanying your pet in-cabin, providing personalized care from
          pick-up to delivery. It ensures a stress-free and comfortable journey
          for your furry friend.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          2. What are the age and health requirements for pets to fly with a
          flight nanny?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
          Pets must be at least 8 weeks old, in good health, and fit to fly.
          Prior disclosure of any special circumstances, complications, or
          allergies is essential for a smooth travel experience.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          3. What size and weight restrictions apply to pets using the flight
          nanny service?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
          Pets should fit in an 18” x 12” x 12” carrier and weigh no more than
          20 lbs. Pre-approval is necessary for pets exceeding this limit, and
          additional fees may apply.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          4. How is communication maintained during the transport with the pet
          nanny?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
          All parties involved need access to a cell phone with text messaging
          capabilities. Regular updates, including photos, are sent via group
          text messages to keep everyone connected throughout the journey.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          5. What happens if my pet is denied transport due to being overweight?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
          While no refund is issued in such cases, our focus is on the safety
          and well-being of your pet. Pre-approval and clear communication help
          avoid last-minute challenges, ensuring a positive experience.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          6. How are pick-up and delivery coordinated with the flight nanny service?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
        The flight nanny picks up your pet from the shipper at the closest major airport and hand-delivers them to the new owner at the agreed-upon meet-up location, creating a personalized and secure delivery experience.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          7.  Can I provide my own carrier for my pet's transport?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
        Yes, you can provide a carrier if you prefer. However, we also supply a comfortable and airline-approved carrier for your convenience.

        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          8. What distinguishes QT Pet Transport from other pet flight nanny services?
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: 14 }}>
        QT Pet Transport is USDA licensed, holds the #1 position on Google for flight nanny services, boasts 300+ 5-star Google reviews, and maintains an A+ rating with the Better Business Bureau, ensuring top-notch service and customer satisfaction.

        </Typography>
      </Grid>
    </Grid>
  );
}
