/** @format */
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

var bearerToken = "Bearer qtPet#@swagger";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = "/login";
      return false;
    } else {
      return true;
    }
  },
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
    Authorization: bearerToken,
    key: process.env.REACT_APP_PUBLIC_API_AUTH_KEY,
  },
});

export const apiForTokenVerify = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
    Authorization: `Bearer ${secureLocalStorage.getItem(
      process.env.REACT_APP_TOKEN_STORAGE_KEY
    )}`,
  },
});

// Add a request interceptor
// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const token = secureLocalStorage.getItem(
    process.env.REACT_APP_TOKEN_STORAGE_KEY
  );
  if (token) {
    bearerToken = `Bearer ${token}`;
  } else {
    bearerToken = "Bearer qtPet#@swagger";
  }
  request.headers.Authorization = bearerToken;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error.message === "Network Error") {
    return;
  } else if (error.response.status === 401) {
    secureLocalStorage.clear();
    return Promise.reject(error);
  } else if (error.response.status === 404) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default api;
