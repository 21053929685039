import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { DialogContentText } from "@mui/material";

import { useDispatch } from "react-redux";
import { reassignBookingtoNanny } from "../../store/slice/users";
import AdminInput from "../adminInput";

export default function AssignSameNanny({ ...props }) {
  const { open, handleClose, assignData } = props;

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();

  const getListofNanny = () => {
    let data = {
      language: "en",
      nannyId: assignData?.assignedToDelivered?._id,
      bookingId: assignData?._id,
      note: formData?.title,
    };
    dispatch(reassignBookingtoNanny(data));
  };

  const handleSubmit = () => {
    if (!formData?.title) {
      setFormError((formError) => ({
        ...formError,
        title: "Please enter note",
      }));
      return;
    }
    getListofNanny();
    setFormData({
      title: "",
    });


    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText sx={{ p: 2, fontSize: 16 ,fontWeight:500}}>
          Could you please provide a compelling reason why we should consider
          rehiring this nanny ?
        </DialogContentText>
 <Box sx={{px:3 ,mt:2}}>
 <AdminInput
  placeholder={"Write note "}
          value={formData?.title}
          
          onChange={(e) => {
            setFormData((formData) => ({
              ...formData,
              title: e.target.value,
            }));
          }}
          formError={formError?.title}
        />
 </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
