import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import { Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./routes";
import { useEffect, useState } from "react";
import { QTPET } from "./services/apiConstant";
import { apiForTokenVerify } from "./services";
import Loader from "./components/loader/loader";
import secureLocalStorage from "react-secure-storage";
// import { onMessageListener } from "./firebase/firebaseConfig";
// import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import Notifications from "./components/Notifications/Notifications";
// import { onMessageListener } from "./firebase/firebaseConfig";

function App() {
  const [isVerified, setIsVerified] = useState({});

  useEffect(() => {
    setIsVerified((isVerified) => ({
      ...isVerified,
      isLoading: true,
    }));
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      await apiForTokenVerify.get(QTPET.cardList).then((response) => {
        if (response) {
          setIsVerified((isVerified) => ({
            ...isVerified,
            verify: true,
            isLoading: false,
          }));
        }
      });
    } catch (e) {
      setIsVerified((isVerified) => ({
        ...isVerified,
        verify: true,
        isLoading: false,
      }));
      secureLocalStorage.clear();
    }
  };


  const CloseButton = ({ closeToast }) => (
    <Button size="small" onClick={closeToast} style={{ color: "white" }}>
      Ok
    </Button>
  );
  return (
    <>
      <Loader open={isVerified.isLoading} text={"Please Wait"} />
      
      <Notifications />
      {isVerified.verify ? (
        <>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Router />
            </BrowserRouter>
          </HelmetProvider>
          <ToastContainer
            theme="colored"
            position={toast.POSITION.TOP_RIGHT}
            autoClose={3000}
            hideProgressBar={true}
            pauseOnHover
            draggable
            icon={false}
            closeButton={CloseButton}
            limit={1}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default App;
