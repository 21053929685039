import { QTPET } from "../../services/apiConstant";
import api from "../../services";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const slice = createSlice({
  name: "chatSlice",
  initialState: {
    chatListData: [],
    messageListData: [],
    groupMember: null,
    receiveMessages: null,
    getChatListMember: [],
    addNewMember: null,
    getRecordingData: null,
    chatListTotalCount: null,
    addNewUserData: "",
  },

  reducers: {
    recodingListSuccess: (state, action) => {
      state.getRecordingData = action.payload.data.data;
    },
    getChatListSuccess: (state, action) => {
      state.chatListData = action.payload.data;
      state.chatListTotalCount = action.payload.total;
    },

    messageListSuccess: (state, action) => {
      state.messageListData = action?.payload?.data?.reverse();
    },
    receiveMessageDataSuccess: (state, action) => {
      if (state.messageListData?.length > 0) {
        if (state.messageListData[0]?.bookingId == action.payload.bookingId) {
          let index = state.messageListData?.findIndex(
            (item) => item._id === action.payload._id
          );
          if (index < 0) {
            state.messageListData = [...state.messageListData, action.payload];
          }
        }
      } else {
        let index = state.messageListData?.findIndex(
          (item) => item._id === action.payload._id
        );
        if (index < 0) {
          state.messageListData = [...state.messageListData, action.payload];
        }
      }
    },
    getChatListMemberSuccess: (state, action) => {
      // console.log("action.payLoad == ", action.payload);

      state.getChatListMember = action.payload.data.data;
    },
    addNewMemberSuccess: (state, action) => {
      let userId = action.payload.id;
      let bookingId = action.payload.bookingId;
      const bookingIndex = state.chatListData?.findIndex(
        (item) => item.bookingId == bookingId
      );
      const index = userId.map((userId) =>
        state.getChatListMember.findIndex((item) => item.userId === userId)
      );
      index.forEach((index) => {
        state.chatListData[bookingIndex].groupMember.push(
          state.getChatListMember[index]
        );
      });
    },
    manageChatListing: (state, action) => {
      let obj = state.chatListData[action.payload];
      state.chatListData.splice(action.payload, 1);
      state.chatListData.splice(0, 0, obj);
    },
    clearUnreadCount: (state, action) => {
      let listIndex = state.chatListData?.findIndex(
        (item) => item.bookingId == action.payload
      );
      if (listIndex >= 0) {
        state.chatListData[listIndex].unreadCount = 0;
      }
    },
    addNewUserSuccess: (state, action) => {
      state.addNewUserData = action.payload;
    },
  },
});

const {
  getChatListSuccess,
  messageListSuccess,
  receiveMessageDataSuccess,
  getChatListMemberSuccess,
  addNewUserSuccess,
  addNewMemberSuccess,
  recodingListSuccess,
  manageChatListing,
  clearUnreadCount,
  // setGroupDataSuccess,
} = slice.actions;

// ReceiveMessage Data

export const receiveMessageData =
  (data, chatListData, getChatListing) => async (dispatch) => {
    let listIndex = chatListData?.findIndex(
      (item) => item.bookingId == data.bookingId
    );
    if (listIndex > 0) {
      dispatch(manageChatListing(listIndex));
    } else {
      getChatListing("", "", true);
    }
    dispatch(receiveMessageDataSuccess(data));
  };

// getPromoCodeList

export const getChatHistoryList =
  (requestParam, setShowLoader) => async (dispatch) => {
    try {
      await api
        .post(QTPET.getChatHistoryList, requestParam)
        .then((response) => {
          if (response) {
            dispatch(getChatListSuccess(response));
          }
          setShowLoader(false);
        });
    } catch (e) {
      return console.error(e);
    }
  };

export const getChatList =
  (requestParam, setShowLoader, setViewMoreLoader) => async (dispatch) => {
    try {
      await api
        .post(QTPET.getChatHistoryList, requestParam)
        .then((response) => {
          if (response) {
            dispatch(getChatListSuccess(response.data));
          }
          if (setViewMoreLoader) {
            setViewMoreLoader(false);
          }
          if (setShowLoader) {
            setShowLoader(false);
          }
        });
    } catch (e) {
      return console.error(e);
    }
  };

export const fetchMessageList =
  (requestParam, setIsLoading) => async (dispatch) => {
    // console.log("fetchMessageList response ============ ", requestParam);
    try {
      await api.post(QTPET.getMessageList, requestParam).then((response) => {
        if (response) {
          dispatch(messageListSuccess(response.data));
          dispatch(clearUnreadCount(requestParam?.bookingId));
          // console.log("mesage list",response.data)
        }
        setIsLoading && setIsLoading(false);
      });
    } catch (e) {
      return console.error(e);
    }
  };
export const getRecordingList = (data) => async (dispatch) => {
  try {
    await api
      .get(
        `${QTPET.getRecording}?language=${data?.language}&page=${data?.page}&perPage=${data?.perPage}&bookingId=${data?.bookingId}`
      )
      .then((response) => {
        if (response) {
          console.log("response", response);
          dispatch(recodingListSuccess(response));
        } else {
          toast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const fetchNewMemberList = (requestParam) => async (dispatch) => {
  try {
    await api.post(QTPET.getChatMemberList, requestParam).then((response) => {
      if (response) {
        dispatch(getChatListMemberSuccess(response));
      }
    });
  } catch (e) {
    return console.error(e);
  }
};
export const addNewmember = (requestParam) => async (dispatch) => {
  try {
    await api.post(QTPET.addMember, requestParam).then((response) => {
      if (response) {
        let param = {
          bookingId: requestParam.bookingId,
          id: requestParam.newUserId,
        };
        dispatch(addNewMemberSuccess(param));
        toast.success(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e);
  }
};
export const addNewuser = (requestParam, setShowLoader) => async (dispatch) => {
  setShowLoader(true);
  try {
    await api.post(QTPET.addNewUser, requestParam).then((response) => {
     
      if (response) {
        dispatch(addNewUserSuccess(response));
        setShowLoader(false);
      }
    });
  } catch (e) {
    return console.error(e);
  }
};

// export const setGroupData = (requestParam) => async (dispatch) => {
//   dispatch(setGroupDataSuccess(requestParam));
// };

export default slice.reducer;
