import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Video from "../../assets/img/splash.mp4";
import theme from "../../theme";
import { adminLogin } from "../../store/slice/auth";
import { useDispatch, useSelector } from "react-redux";
import { appColors } from "../../theme/colors";
import Loader from "../../components/loader/loader";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.authSlice);
  const fcmTOKEN = Cookies.get("token");
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter your email address",
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }
    if (!password) {
      setFormError((formError) => ({
        ...formError,
        password: "Please enter a password",
      }));
      return;
    }
    if (password.length <= 7) {
      setFormError((formError) => ({
        ...formError,
        password: "Password must be  8 characters ",
      }));
      return;
    }
    // secureLocalStorage.setItem("authenticated", true);
    var data = {
      email: email,
      password: password,
      userType: 1, //1 for adminType
      loginType: 1,
      deviceToken: fcmTOKEN,
      deviceType: "web",
    };
    dispatch(adminLogin(data, navigate));
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{
            backgroundColor: appColors.main,
            height: "100vh",
            padding: { xs: false, sm: false, md: 10, lg: 10 },
            mt: isMobile ? 0 : -5,
          }}
        >
          <CssBaseline />
          {!isMobile && (
            <Grid
              item
              xs={12}
              sm={4}
              md={5}
              sx={{
                background: "black",
                borderRadius: 1,
                height: "85vh",
              }}
            >
              <video
                loop
                playsInline
                autoPlay
                muted
                id="video"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  pointerEvents: "none",
                  // filter: "brightness(0.7)",
                  borderRadius: 1,
                }}
              >
                <source src={Video} type="video/mp4" />
              </video>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            component={Paper}
            square
            sx={{ borderRadius: 1, height: "85vh" }}
          >
            <Box
              sx={{
                // my: isMobile ? 5 : 6,
                mt: "20%",
                mx: isMobile ? 4 : 15,
                //   display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: appColors.main,
                  width: 50,
                  height: 50,
                  marginLeft: 19,
                }}
              >
                <Icon icon="fluent-mdl2:signin" />
              </Avatar>
              <Typography
                variant="h1"
                sx={{ textAlign: "center", fontSize: 21, letterSpacing: 0.5 }}
              >
                QT PET
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center", pt: 1 }}>
                Sign in to continue
              </Typography>
              <Box noValidate sx={{ mt: 1 }}>
                <AdminInput
                  title="Email Address"
                  type="email"
                  value={email}
                  onChange={(val) => {
                    if (val) {
                      setEmail(val.target.value);
                      setFormError((formError) => ({
                        ...formError,
                        email: "",
                      }));
                    }
                  }}
                  formError={formError.email}
                />
                <Stack sx={{ mt: 2 }}>
                  <AdminInput
                    title="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(val) => {
                      if (val) {
                        setPassword(val.target.value);
                        setFormError((formError) => ({
                          ...formError,
                          password: "",
                        }));
                      }
                    }}
                    formError={formError.password}
                    endIcon={
                      <Icon
                        icon={
                          !showPassword ? "tabler:eye-off" : "eva:eye-outline"
                        }
                        width="28"
                        height="28"
                        color="black"
                      />
                    }
                    PasswordShowClick={() => setShowPassword(!showPassword)}
                  />
                </Stack>
                <Link
                  sx={{
                    float: "right",
                    textDecoration: "none",
                    fontSize: 15,
                    fontWeight: 600,
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                  href="forgotPassword"
                >
                  Forgot password?
                </Link>
                <AdminButton
                  onClick={handleSubmit}
                  title={"Sign IN"}
                  fullWidth="true"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
      </ThemeProvider>
    </>
  );
}
