import { Grid, Card, Typography, Box, Divider } from "@mui/material";
import image from "../../assets/img/Flight-Nanny-for-Pets.png";
import AdminButton from "../../components/adminButton";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { appColors } from "../../theme/colors";
import AdminInput from "../../components/adminInput";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, getAdminProfileDetails } from "../../store";
import Spinner from "../../components/spinner";
// sections

// ----------------------------------------------------------------------

export default function Profile() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { adminProfileData } = useSelector((state) => state.userSlice);

  const handleSubmit = () => {
    if (!formData.oldPassword) {
      setFormError((formError) => ({
        ...formError,
        oldPassword: "Please enter old password",
      }));
      return;
    }
    if (!formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Please enter a password",
      }));
      return;
    }
    if (!formData.confirmPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Please enter a password",
      }));
      return;
    }

    if (formData.confirmPassword !== formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Confirm password dosent match, Try again",
      }));
      return;
    }

    var data = {
      currentPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };

    dispatch(changePassword(data));
  };

  useEffect(() => {
    var data = {
      userId: "",
    };
    dispatch(getAdminProfileDetails(data, setShowLoader));
  }, []);

  return (
    <>
      <Card sx={{ maxWidth: "92vw", p: 5, color: appColors.fourth }}>
        <Grid container component="main" sx={{ alignItems: "center" }}>
          <Grid item md={2}>
            <Box sx={{ width: 150, my: "10px" }}>
              <img
                src={image}
                alt="logo"
                style={{ width: "100%", borderRadius: "50%" }}
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            <Typography sx={{ fontWeight: "bold" }} component="h1" variant="h6">
              Admin
            </Typography>
            <Typography>{adminProfileData?.email}</Typography>
            {/* <Box sx={{ position: "relative", height: '3.5rem', width: "20rem", cursor: "pointer", my: 1, border: `0.5px dashed ${appColors.fourth}`, textAlign: "center", pb: 5, pt: 2 }}>
                            {isUploading ?
                                <Icon icon='line-md:uploading-loop' width="30" height="30" />
                                :
                                <>
                                    <Typography>
                                        Upload profile
                                    </Typography>
                                    <input
                                        type="file"
                                        style={{ position: ' absolute', top: 0, left: 0, height: "100%", width: '100%', opacity: 0 }}
                                        className="imageUpload"
                                        accept="image/*"
                                        // onChange={onChange}
                                    />
                                </>}
                        </Box> */}
          </Grid>
        </Grid>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Grid container sx={{ display: "flex" }}>
          <Grid xs={6}>
            {" "}
            <Box sx={{ display: "flex", alignItems: "center", my: "10px" }}>
              <Typography
                sx={{ m: 2, fontSize: 20 }}
                component="h1"
                variant="h5"
              >
                My Profile
              </Typography>
            </Box>
            <Grid
              container
              component="main"
              sx={{ alignItems: "center", px: 4 }}
            >
              <>
                <Grid container>
                  <Grid xs={1}>
                    <Icon icon={"ph:user-light"} width="28" height="28" />
                  </Grid>

                  <Grid xs={5}>
                    <Typography sx={{ ml: 1 }}>Name</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography>
                      {adminProfileData?.firstName}&nbsp;
                      {adminProfileData?.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={1}>
                    <Icon
                      icon={"iconamoon:email-thin"}
                      width="28"
                      height="28"
                    />
                  </Grid>

                  <Grid xs={5}>
                    <Typography sx={{ ml: 1 }}>Email</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography>{adminProfileData?.email}</Typography>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
          <Grid xs={5}>
            <Box sx={{ display: "flex", alignItems: "center", my: "10px" }}>
              <Typography
                sx={{ m: 2, fontSize: 20 }}
                component="h1"
                variant="h5"
              >
                Change Password
              </Typography>
            </Box>
            <Box noValidate sx={{ mt: 1 }}>
              <AdminInput
                title="Old password"
                type={showPassword.old ? "text" : "password"}
                value={formData.oldPassword}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      oldPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        oldPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.oldPassword}
                endIcon={
                  <Icon
                    icon={showPassword.old ? "iconamoon:eye-off" : "tabler:eye"}
                    width="28"
                    height="28"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    old: !showPassword.old,
                  }))
                }
              />
              <AdminInput
                title="New password"
                type={showPassword.new ? "text" : "password"}
                value={formData.newPassword}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      newPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        newPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.newPassword}
                endIcon={
                  <Icon
                    icon={showPassword.new ? "iconamoon:eye-off" : "tabler:eye"}
                    width="28"
                    height="28"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    new: !showPassword.new,
                  }))
                }
              />
              <AdminInput
                title="Confirm password "
                type={showPassword.confirmPassword ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      confirmPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        confirmPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.confirmPassword}
                endIcon={
                  <Icon
                    icon={
                      showPassword.confirmPassword
                        ? "iconamoon:eye-off"
                        : "tabler:eye"
                    }
                    width="28"
                    height="28"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  }))
                }
              />
              <AdminButton
                onClick={handleSubmit}
                title={"Submit"}
                fullWidth={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>

    

      {showLoader ? (
        <>
          <Box
            style={{
              position: "absolute",
              top: "69%",
              left: "50%",
            }}
          >
            <Spinner />
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
