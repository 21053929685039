import Logo from "../../../../../assets/img/default.jpg";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { socket } from "../../../../../helpers/socketio-client";
import {
  fetchMessageList,
  receiveMessageData,
} from "../../../../../store/slice/chat";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { appColors } from "../../../../../theme/colors";
import InfiniteScroll from "react-infinite-scroll-component";

const Message = (props) => {
  const { loginData, messageListData, itemData, getChatListing } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { chatListData } = useSelector((state) => state.chatSlice);
  const [chatData, setChatData] = useState(null);
  const perPage = 5;
  const dispatch = useDispatch();
  const handleScroll = (perPage) => {
    setIsLoading(true);
    var data = {
      bookingId: itemData?.bookingId,
      page: "1",
      perPage: perPage ? perPage : "5",
    };
    dispatch(fetchMessageList(data, setIsLoading));
  };

  useEffect(() => {
    socket.on("receiveMessage", handleReceiveMessage);
  }, [socket]);
  useEffect(() => {
    if (chatData) {
      dispatch(receiveMessageData(chatData, chatListData, getChatListing));
    }
  }, [chatData]);
  const handleReceiveMessage = (data) => {
    setChatData(data);
  };

  return (
    <InfiniteScroll
      dataLength={messageListData?.length ? messageListData?.length : 0}
      next={() => handleScroll(perPage + 5)}
      inverse={true}
      hasMore={true}
      scrollableTarget="scrollableDiv"
    >
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress thickness="4" size={30} sx={{ color: "red" }} />
        </Box>
      )}

      <>
        {messageListData?.map((item) => {
          return (
            <>
              {loginData?.userId !== item?.senderId?.userId ? (
                <>
                  <Box
                    key={item}
                    sx={{
                      display: "flex",
                      width: "60%",
                      my: 1,
                      alignItems: "center",
                      py: 0.5,
                      px: 2,
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <Avatar
                        sx={{
                          width: "40",
                          height: "40",
                          borderRadius: "50%",
                        }}
                        alt="Cindy Baker"
                        src={Logo}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ color: "gray", fontSize: "0.7rem" }}>
                        {item?.senderId?.name},{" "}
                        {moment(item?.time).format("hh:mm a")}
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          backgroundColor:
                            item?.dataType === "text" ? appColors.topbar : "",

                          width: "60%",
                        }}
                      >
                        {item?.dataType === "text" ? (
                          <Typography
                            sx={{
                              color: "white",
                              maxWidth: "-webkit-fill-available",
                              wordWrap: "break-word",
                              marginRight: "auto",
                              textAlign: "left ",
                            }}
                          >
                            {" "}
                            {item?.data}
                          </Typography>
                        ) : (
                          <img src={item?.data} width={100} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  key={item}
                  sx={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    float: "right",
                    // alignItems:"end",
                    my: 1,
                    py: 0.5,
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{ color: "gray", fontSize: "0.7rem", textAlign: "end" }}
                  >
                    {moment(item?.time).format("hh:mm a")}
                  </Typography>
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor:
                        item?.dataType === "text" ? "#eef0f7" : "",

                      textAlign: "left ",
                      marginLeft: "auto",
                      maxWidth: "-webkit-fill-available",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography variant="subtitle2">{item?.data}</Typography>
                  </Box>
                </Box>
              )
              }
              {
                  item.dataType==="notify" &&            <Box
                  key={item}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column", // Stack items vertically
                    alignItems: "center", // Center items horizontally
                    justifyContent: "center", // Center items vertically
                    textAlign: "center", // Center text alignment
                  }}
                >
                  <Typography
                    sx={{ color: "gray", fontSize: "0.7rem", mb: 1 }} // Add margin-bottom to space out from the text box
                  >
                    {moment(item?.time).format("hh:mm a")}
                  </Typography>
                  <Box
                    sx={{
                      p: 1,
                      display:"flex",
                      backgroundColor: "#ebebeb",
                      maxWidth: "fit-content", // Ensure the box does not exceed container width
                      wordWrap: "break-word",
                      borderRadius:4,

                    }}
                  >
                    <Typography variant="subtitle2" sx={{fontSize:10}}>
                   {item.data}
                    </Typography>
                  </Box>
                </Box>
              }
            </>
          );
        })}
      </>
    </InfiniteScroll>
  );
};
export default React.memo(Message);
