/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DialogTitle } from "@mui/material";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { appColors } from "../../theme/colors";
import defaultImg from "../../assets/img/default.jpg";
import { Grid } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import moment from "moment";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { bookingDetail } from "../../store";
import MUIDataTable, { TableHead } from "mui-datatables";

import { getRecordingList } from "../../store/slice/chat";

// import ReactPlayer from "react-player";
import AudioPlayer from "../../pages/dashboard/bookings/audioPlayer";

// import "./index.css";

export default function ViewAssignDetails({ ...props }) {
  const { open, handleClose, assignData, title } = props;
  const { bookingDetailData } = useSelector((state) => state.userSlice);
  const { getRecordingData } = useSelector((state) => state.chatSlice);
  console.log("getRecordingData", getRecordingData);
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));
  // console.log("Booking Assign", assignData);

  const dispatch = useDispatch();

  useEffect(() => {
    let bookingId = assignData?._id;
    let param = {
      language: "en",
      page: 1,
      perPage: 20,
      bookingId: bookingId,
    };
    dispatch(getRecordingList(param));
    dispatch(bookingDetail(bookingId));
  }, []);
  const options = {
    count: 2,
    filter: false,
    pagination: false,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    // onChangePage(currentPage) {
    //   if (currentPage >= pagecheck) {
    //     if (currentPage === 0) {
    //       currentPage = currentPage + 1;
    //     }
    //     setPageCheck(currentPage);
    //     page = currentPage + 1;

    //     bookingList(page, "", "");
    //   } else {
    //     if (currentPage > 0) {
    //       page = currentPage + 1;
    //     }
    //     setPageCheck(currentPage);
    //     bookingList(page, "", "");
    //   }
    // },
    // onChangeRowsPerPage(numberOfRows) {
    //   setNumberOfRows(numberOfRows);
    //   bookingList(page, numberOfRows, "");
    // },
  };

  const columns = [
    {
      name: "time",
      label: "Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {moment(value ? value : "-").format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "fileName",
      label: "Recording",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          console.log(value);

          return (
            <Box sx={{ width: "60px" }}>
              <AudioPlayer src={value} />
            </Box>
          );
        },
      },
    },
  ];
  const RecordData = [
    {
      recordingDate: "05-25-2024",
      duration: "3:00",
      recoding: "null",
    },
    {
      recordingDate: "05-25-2024",
      duration: "3:00",
      recoding:
        "https://qt-prod-asset-pub.s3.amazonaws.com/5SbN6sTp3k/96a9f31e134826f4272f6f97460f6c50_5SbN6sTp3k.m3u8",
    },
    {
      recordingDate: "05-25-2024",
      duration: "3:00",
      recoding: "null",
    },
  ];
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          position: "inherit",
        },
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12}>
          <DialogTitle style={{ display: "flex", justifyContent: "between" }}>
            <Grid item>
              <Typography sx={{ color: appColors?.topbar, fontSize: 18 }}>
                Booking Id&nbsp;:&nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 18 }}>
                {bookingDetailData?.bookingNumber}
              </Typography>
            </Grid>
          </DialogTitle>
        </Grid>
      </Grid>

      <Divider />
      <DialogContent style={{ overflowY: "scroll" }} className="overflow">
        <DialogContentText id="alert-dialog-slide-description">
          {assignData?.otherReason !== null && (
            <Grid container sx={{ p: isMobile ? 0 : 4, pb: 0 }}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 1,

                    p: 2,
                    px: isMobile ? "" : 4,
                    display: "flex",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 200 }}>
                    Reschedule Reason
                  </Typography>
                  <Typography variant="h6" sx={{ mx: 4 }}>
                    :
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 200, textAlign: "start" }}
                  >
                    {bookingDetailData?.otherReason}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          )}

          <Grid container sx={{ p: isMobile ? 0 : 3, pt: 2 }}>
            {" "}
            <Grid
              item
              xs={isMobile ? 12 : 6}
              sx={{ fontSize: isMobile ? 12 : "" }}
            >
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: 1,
                  m: 1,
                  p: 2,
                  height: 260,
                  px: isMobile ? "" : 4,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Flight Information
                </Typography>

                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Pickup Airport
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {" "}
                      {bookingDetailData?.pickAirpot?.airpotName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Delivered Airport
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.deliveredAirpot?.airpotName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Delivery Date
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {moment(bookingDetailData?.deliveryDate).format(
                        "MM/DD/YYYY"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Status
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {" "}
                      {bookingDetailData?.isStandbyConfirmed == 0 ? (
                        <>Confirm</>
                      ) : (
                        <>StandBy</>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {/* payment Info */}
            <Grid item xs={isMobile ? 12 : 6}>
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: 1,
                  m: 1,
                  p: 2,
                  px: isMobile ? "" : 4,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Payment Info
                </Typography>

                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Deposit Amount
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      ${bookingDetailData?.payDepositAmount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Remaining Amount
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      $
                      {bookingDetailData?.remainingAmount !== null
                        ? bookingDetailData?.remainingAmount
                        : " " + 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Discount
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      $
                      {bookingDetailData?.discountValue !== null
                        ? bookingDetailData?.discountValue
                        : " " + 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Total Discount
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      $
                      {bookingDetailData?.totalDiscount !== null
                        ? bookingDetailData?.totalDiscount
                        : " " + 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Remaining Amount
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      $
                      {bookingDetailData?.remainingAmount !== null
                        ? bookingDetailData?.remainingAmount
                        : " " + 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      sx={{ color: appColors?.topbar, fontSize: 16 }}
                      variant="h6"
                    >
                      Grand Total
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                      variant="h6"
                    >
                      $ &nbsp;{bookingDetailData?.grandTotal}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {/* pet Information */}
          <Card
            sx={{
              m: isMobile ? 1 : 4,
              p: isMobile ? 1 : 3,
              boxShadow: 3,
              my: 0,
            }}
          >
            <Grid container sx={{ p: 1, borderRadius: 1 }}>
              <Grid item xs={isMobile ? 12 : 5}>
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Pet Information
                </Typography>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Breed
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.breed}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Age
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.petAge}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Breed
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {" "}
                      {bookingDetailData?.breed}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Weight
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.weight}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Pet
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.pets}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 3.5}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Typography variant="h6"> Pet Images</Typography>
                  <ImageList
                    sx={{ minWidth: isMobile?350:200 }}
                    cols={2}
                    rowHeight={150}
                    className="overflow"
                  >
                    {bookingDetailData?.petImages?.map((item) => (
                      <ImageListItem key={item}>
                        <img
                          srcSet={item}
                          alt={item.title}
                          loading="lazy"
                          style={{ margin: 1, height: "10vh" }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3.5}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Typography variant="h6"> Insurence Images</Typography>
                  <ImageList
                    sx={{ minWidth: isMobile?350:200 }}
                    cols={2}
                    rowHeight={150}
                    className="overflow"
                  >
                    {bookingDetailData?.healthCertificates?.map((item) => (
                      <ImageListItem key={item}>
                        <img
                          srcSet={item}
                          alt={item.title}
                          loading="lazy"
                          style={{ margin: 1, height: "10vh" }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Grid>
            </Grid>
          </Card>
          {/* Booking Party */}
          <Grid container sx={{ p: isMobile ? 0 : 3, mt: 0 }}>
            {" "}
            <Grid
              item
              xs={isMobile ? 12 : 8}
              sx={{ fontSize: isMobile ? 12 : "" }}
            >
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: 1,
                  m: 1,
                  p: 2,
                  height: 282,
                  px: isMobile ? "" : 4,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Booking Party Information
                </Typography>

                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Name
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {" "}
                      {bookingDetailData?.firstName}&nbsp;
                      {bookingDetailData?.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Phone
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      +1 123456789
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Email
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Flight Nanny Name{" "}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {/* payment Info */}
            <Grid item xs={isMobile ? 12 : 4}>
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: 1,
                  m: 1,
                  p: 2,
                  px: isMobile ? "" : 4,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 200, textAlign: "left" }}
                >
                  {" "}
                  Assigned Nanny Information
                </Typography>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: "center", my: 2, paddingRight: 4 }}>
                      <img
                        src={
                          bookingDetailData &&
                          bookingDetailData.assignedToDelivered &&
                          bookingDetailData.assignedToDelivered.profileImage
                            ? bookingDetailData.assignedToDelivered.profileImage
                            : defaultImg
                        }
                        alt=""
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 50,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Name
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.assignedToDelivered?.firstName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Email
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.assignedToDelivered?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {/* seller Card */}
          <Card
            sx={{
              m: isMobile ? 1 : 4,
              p: isMobile ? 1 : 3,
              boxShadow: 3,
              mt: 0,
            }}
          >
            <Grid container sx={{ p: 1, borderRadius: 1 }}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Seller Info
                </Typography>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Seller Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.sellerFirstName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Seller Phone
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.sellerPhoneNumber}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Seller Instagram
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.assignedToDelivered?.firstName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Typography variant="h6">Receiver QR</Typography>
                  <img src={bookingDetailData?.receiverQrImage} width={150} />
                </Box>
              </Grid>
            </Grid>
          </Card>
          {/* breader Details */}
          <Card
            sx={{
              m: isMobile ? 1 : 4,
              p: isMobile ? 1 : 3,
              boxShadow: 3,
              mt: 1,
            }}
          >
            <Grid container sx={{ p: 1 }}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Typography variant="h6" sx={{ fontWeight: 200 }}>
                  {" "}
                  Breeder Info
                </Typography>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Pet Owner Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.petFirstName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: appColors?.topbar, fontSize: 14 }}>
                      Pet Owner Phone
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.petPhoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        color: appColors?.topbar,
                        fontSize: isMobile ? 12 : 14,
                      }}
                    >
                      Pet OwnerEmail
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.petEmail}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        color: appColors?.topbar,
                        fontSize: isMobile ? 12 : 14,
                      }}
                    >
                      Pet Instagram
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: 14, textAlign: isMobile ? "right" : "" }}
                    >
                      {bookingDetailData?.petInstagrameName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Typography variant="h6"> Sender QR</Typography>
                  <img src={bookingDetailData?.senderQrImage} width={150} />
                </Box>
              </Grid>
            </Grid>
          </Card>
          {/* Recording data  */}
          <Card
            sx={{
              m: isMobile ? 1 : 4,
              p: isMobile ? 1 : 0,
              boxShadow: 3,
              mt: 1,
            }}
          >
            <Grid container>
              <Grid xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 200, p: 3 }}>
                  Recording Info
                </Typography>
                {getRecordingData?.length > 0 ? (
                  <MUIDataTable
                    sx={{ background: "green" }}
                    data={getRecordingData}
                    columns={columns}
                    options={options}
                  />
                ) : (
                  <Grid
                    container
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      height: 300,
                      backgroundColor: "white",
                    }}
                  >
                    <Grid
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                      >
                        No Record Found
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <>
          <Button
            variant="Yes"
            sx={{
              m: 2,
              mx: 3,
              color: "white",
              backgroundColor: "#299AD5",
              height: 40,
              ":hover": {
                color: "white",
                backgroundColor: "#5856d6",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
