import { TextField, Typography } from "@mui/material";
import React from "react";
import "./index.css";
import InputAdornment from "@mui/material/InputAdornment";

export default function AdminInput(props) {
  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    required,
    startIcon,
    rows,
    placeholder,
    min,
    max,
    maxLength,
    onKeyDown,
  } = props;
  return (
    <>
      <TextField
        // margin="normal"
        required={required}
        fullWidth
        onKeyDown={onKeyDown}
        inputProps={{
          min: min,
          max: max,
          maxLength: maxLength,
        }}
        maxLength={maxLength}
        type={type}
        InputLabelProps={{
          style: {
            color: "black",
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Poppins",
            letterSpacing: 0.7,
          },
        }}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={type}
        min={min}
        max={max}
        // label={title}
        label={
          title ? (
            <span style={{ marginRight: 8 }}>
              {title}
              {required ? <span style={{ marginLeft: 2 }}>*</span> : ""}
            </span>
          ) : (
            ""
          )
        }
        name={type}
        autoComplete={type}
        multiline={rows}
        rows={rows}
        InputProps={{
          style: {
            color: "black",
            fontFamily: "Poppins",
            fontWeight: "normal",
            letterSpacing: 0.5,
          },
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="start">
              {startIcon}
            </InputAdornment>
          ),
        }}
      />
      <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
