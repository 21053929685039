import React, { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Dialog,
  DialogTitle,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getDashboardData,
  getDeliveryStaffList,
  profileDetail,
} from "../../../store/slice/users";
import AdminInput from "../../../components/adminInput";
import { useEffect } from "react";
import Loader from "../../../components/loader/loader";
import { Box } from "@mui/material";
import AlertDialog from "../../../components/dialog/alertDialog/alertDialog";
import { Card } from "@mui/material";
import DeliveryDashboard from "./subcomponents/deliveryDashboard";
import ViewDetail from "./subcomponents/viewDetail";
import { Chip } from "@mui/material";

export default function DeliveryStaff() {
  var page = 1;
  const perPage = 5;
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userStatusDialog, setUserStatusDialog] = useState({});
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const {
    deliveryStaffData,
    profileDetailData,
    totalCount,
    isLoading,
    dashboardData,
  } = useSelector((state) => state.userSlice);

  var searchInputRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (deliveryStaffData && !isMobile) {
      handleClickOpen(deliveryStaffData[0].userId);
    }
  }, [deliveryStaffData]);

  useEffect(() => {
    if (isMobile && profileDetailData) {
      setViewDialog(true);
    }
  }, [profileDetailData]);
  useEffect(()=>{
    handleClickOpen();
  },[])

  const handleClickOpen = (rowObj) => {
    var requestParams = {
      userId: rowObj,
    };
    dispatch(profileDetail(requestParams, setShowLoader));
  };

  const customerList = (
    page,
    numberOfRows,
    sortingColumnValue,
    sortingOrder,
    search
  ) => {
    var data = {
      userType: 4,
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: search,
      sort: {
        sortField: sortingColumnValue !== "" ? sortingColumnValue : sortColumn,
        sortIn: sortingOrder !== "" ? sortingOrder : sortOrder,
      },
    };
    let isSearch = search ? true : false;

    dispatch(getDeliveryStaffList(data, isSearch, setShowLoader));
  };

  const callDashboard = () => {
    dispatch(
      getDashboardData({
        language: "en",
        startDate: "",
        endDate: ""
      },setShowLoader)
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 1) {
      customerList(page, perPageRows, "", "", e.target.value);
    } else if (e.target.value.length === 0) {
      customerList(page);
    }
  };

  useEffect(() => {
    customerList();
    callDashboard();
  }, []);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      customerList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        customerList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }

        setPageCheck(currentPage);
        customerList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      customerList(page, numberOfRows, "", "");
    },
  };

  const clearSearch = () => {
    setSearchValue("");
    customerList(page);
  };

  const dashboardDataConfig = [
    {
      color: "#2554fb",
      backgroundColor: "#e1e7fc",
      icon: "ph:users-three",
      title: "Total delivery staff",
      count: dashboardData?.totalNanny ? dashboardData.totalNanny : 0,
    },
    {
      color: "#000",
      backgroundColor: "#ddddde",
      icon: "basil:user-clock-outline",
      title: " Pending account",
      count: dashboardData?.pendingNanny ? dashboardData.pendingNanny : 0,
    },
    {
      color: "#03ad4f",
      backgroundColor: "#e0f7ea",
      icon: "tabler:user-check",
      title: "Active account",
      count: dashboardData?.activeNanny ? dashboardData.activeNanny : 0,
    },
    {
      color: "#fb2727",
      backgroundColor: "#ffe9e9",
      icon: "iconoir:user-xmark",
      title: "Suspended account",
      count: dashboardData?.suspendedNanny ? dashboardData.suspendedNanny : 0,
    },
  ];

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "email",
      label: "Email",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    

    {
      name: "status",
      label: "Account Status",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              {value === 1 || value === 2 ? (
                <Chip
                  label={value === 1 ? "Active" : "Suspended"}
                  sx={{
                    backgroundColor: value === 1 ? "#e0f7ea" : "#ffe9e9",
                    color: value === 1 ? "green" : "red",
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
          );
        },
      },
    },

    {
      name: "availableToStart",
      label: "Available To Start",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return moment(value).format("MMM/DD/yyyy");
        },
      },
    },
    {
      name: "noOfBooking",
      label: "Booking",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "00"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "totalRevenue",
      label: "Revenue",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value ? value : "00"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Icon
                icon="icon-park-outline:preview-open"
                color="#21618C"
                width="25"
                height="25"
                onClick={() => {
                  handleClickOpen(
                    deliveryStaffData[tableMeta.rowIndex]?.userId
                  );
                }}
              />
            </Box>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Delivery Staff </title>
      </Helmet>
      <Box sx={{ mt: isMobile ? -2 : 0 }}>
        <Grid container sx={{ justifyContent: "space-between", pb: 2 }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Delivery Staff</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={5}>
        {dashboardDataConfig?.map((item, i) => (
          <Grid item xs={12} md={3} key={i}>
            <DeliveryDashboard
              color={item.color}
              backgroundColor={item.backgroundColor}
              icon={item.icon}
              title={item.title}
              count={item.count}
            />
          </Grid>
        ))}
      </Grid>
      <div className="App wrapper">
        {isLoading ? (
          <>
            <Grid
              container
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                mt: 2,
                height: 300,
                backgroundColor: "white",
              }}
            >
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            <>
              <Grid container spacing={2} sx={{ mt: 0 }} height="100%" >
                <Grid item xs={12} md={7.5} height="100%">
                  <Card sx={{ pb: 1 ,pt:2}}>
                    <Grid container>
                      <Grid item xs={isMobile ? 12 : 4} sx={{ px: 3 }}>
                        <AdminInput
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              customerList(page);
                            }
                          }}
                          inputRef={searchInputRef}
                          title="Search delivery staff"
                          type="email"
                          endIcon={
                            searchValue && (
                              <Icon
                                icon="material-symbols:cancel"
                                style={{ cursor: "pointer" }}
                                color="red"
                                onClick={() => clearSearch()}
                              />
                            )
                          }
                          onChange={handleSearchChange}
                          value={searchValue}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                  {deliveryStaffData && deliveryStaffData.length > 0 ? (
                    <Card sx={{ mt: 2 }}>
                      <MUIDataTable
                        data={deliveryStaffData}
                        columns={columns}
                        options={options}
                      />
                    </Card>
                  ) : (
                    <>
              { !showLoader && <Grid
                        container
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          height: 300,
                          backgroundColor: "white",
                        }}
                      >
                        <Grid
                          xs={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography
                            sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                          >
                            No Record Found
                          </Typography>
                        </Grid>
                      </Grid>}
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4.5}
                  height="100%"
                  sx={{ display: isMobile ? "none" : "block" }}
                >
                  {profileDetailData ? (
                    <ViewDetail
                      profileDetailData={profileDetailData}
                      setShowLoader={setShowLoader}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          </>
        )}
      </div>
      <AlertDialog
        open={userStatusDialog?.open}
        title="Warning"
        text={`Are you want to sure ${userStatusDialog?.message} this account`}
        handleClose={setUserStatusDialog}
        handleAgree={() => userStatusDialog?.funCall()}
      />
      {viewDialog ? (
        <>
          <Dialog onClose={() => setViewDialog(false)} open={viewDialog}>
            <Grid container sx={{ display: "flex" }}>
              <Grid xs={10.5}>
                <DialogTitle>Set backup account</DialogTitle>
              </Grid>
              <Grid xs={1.5} sx={{ my: "auto" }}>
                <Icon
                  icon="gridicons:cross-circle"
                  width="25"
                  height="25"
                  color="red"
                  onClick={() => setViewDialog(false)}
                />
              </Grid>
            </Grid>
            <ViewDetail
              profileDetailData={profileDetailData}
              callDashboard={callDashboard}
            />
          </Dialog>
        </>
      ) : (
        ""
      )}
      {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
    </ThemeProvider>
  );
}
