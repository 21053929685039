import {
  Box,
  // ButtonGroup,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Grid } from "@mui/material";
import { Card } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../../../assets/img/default.jpg";
import moment from "moment";
import "./style.css";
import { useTheme } from "@emotion/react";
import { Chip } from "@mui/material";
import AlertDialog from "../../../../components/dialog/alertDialog/alertDialog";
import { useDispatch } from "react-redux";
import {
  getDashboardData,
  suspendAccountDelivery,
  upDateNannyDocs,
} from "../../../../store/slice/users";
import { Link } from "@mui/material";
import { appColors } from "../../../../theme/colors";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const ViewDetail = ({ ...props }) => {
  const { profileDetailData, setShowLoader } = props;
  const dispatch = useDispatch();
  var mobiletheme = useTheme();
  const navigate = useNavigate();
  // const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const isTab = useMediaQuery(mobiletheme.breakpoints.down("md"));
  const [userStatusDialog, setUserStatusDialog] = useState({});

  const handleChange = () => {
    var data = {
      type: 1,
      userId: profileDetailData.userId,
      status: profileDetailData.status === 1 ? "2" : "1",
    };
    dispatch(suspendAccountDelivery(data, setShowLoader));
    dispatch(getDashboardData({ language: "en" }));
  };

  // const handleApproveReject = (status, type, isVarified) => {
  //   let request = {
  //     type: 0,
  //     userId: profileDetailData?.userId,
  //     status: status,
  //     ssnVarified: type == "SSN" ? isVarified : profileDetailData.ssnVarified,
  //     passportVarified:
  //       type == "passport" ? isVarified : profileDetailData.passportVarified,
  //     docFileVarified:
  //       type == "document" ? isVarified : profileDetailData.docFileVarified,
  //   };
  //   dispatch(suspendAccountDelivery(request, setShowLoader));
  // };
  const handleStatus = (status, doc) => {
    const request = {
      language: "en",
      nannyId: profileDetailData?.userId,
      status: status,
      docType: doc,
    };
    dispatch(upDateNannyDocs(request, setShowLoader));
  };
  console.log(profileDetailData.isEmailverified);
  return (
    <>
      <Card
        className="viewProfile"
        variant="outlined"
        sx={{
          px: 5,
          pt: 5,
        //   height:"100vh",
         maxHeight: "100%",
          overflow: "auto",
          overflowX:"hidden",
          mx: isTab ? "auto" : "",
        }}
      >
        <Grid container>
          <Grid xs={3}>
            <Box>
           
    <img src={profileDetailData?.profileImageUrl?profileDetailData?.profileImageUrl:Logo} alt=""   style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}/>
            </Box>
          </Grid>
          <Grid xs={9} lg={9}>
            <Box
              sx={{
                p: 1,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {profileDetailData.firstName} {profileDetailData.lastName}
              </Typography>
              <Tooltip title={profileDetailData.email}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {profileDetailData.email ? profileDetailData.email : "-"}
                </Typography>
              </Tooltip>
              <Typography sx={{ color: "green", fontSize: "small" }}>
                <Chip
                  label={
                    profileDetailData.isAdminverified === 0
                      ? "Pending"
                      : profileDetailData.isAdminverified === 1
                        ? "Approve"
                        : "Reject"
                  }
                  sx={{
                    backgroundColor:
                      profileDetailData.isAdminverified == 0
                        ? "#ddddde"
                        : profileDetailData.isAdminverified == 1
                          ? "#e0f7ea"
                          : "#ffe9e9",
                    color:
                      profileDetailData.isAdminverified == 0
                        ? "#000"
                        : profileDetailData.isAdminverified == 1
                          ? "green"
                          : "red",
                    mt: 1,
                  }}
                />
              </Typography>
            </Box>
          </Grid>
          {/* <Grid xs={12} lg={4} sx={{ marginLeft: isTab ? "20%" : 0 }}>
            <Box
              sx={{
                p: 1,
              }}
            >
              <Box sx={{display:"flex",marginRight:"-25px"}}>
                <AdminButton
                  title="Approve"
                  sx={{
                    m: 0,
                    px: 0.5,
                    py: 0.5,
                    width: "80px",
                    height: "fit-content",
                    backgroundColor: "#e0f7ea",
                    color: "green",
                    borderRight: "none !important",
                    display:
                      profileDetailData.isAdminverified == 1 ? "none" : "",
                  }}
                  hoverBackgroundColor="#e0f7ea"
                  hoverColor='green'
                  onClick={() => {
                    setUserStatusDialog((userStatusDialog) => ({
                      ...userStatusDialog,
                      open: true,
                      message: "approve",
                      funCall: () => {
                        handleApproveReject(1);
                      },
                    }));
                  }}
                />
                <AdminButton
                  title="Reject"
                  sx={{
                    m: 0,
                    px: 0.5,
                    py: 0.5,
                    width: "80px",
                    height: "fit-content",
                    backgroundColor: "#ffe9e9",
                    color: "#fb2727",
                    display:
                      profileDetailData.isAdminverified == 2 ? "none" : "",
                  }}
                  hoverBackgroundColor="#ffe9e9"
                  hoverColor='#fb2727'
                  onClick={() => {
                    setUserStatusDialog((userStatusDialog) => ({
                      ...userStatusDialog,
                      open: true,
                      message: "reject",
                      funCall: () => {
                        handleApproveReject(2);
                      },
                    }));
                  }}
                />
                </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Phone number
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.phoneNumber
                ? `${profileDetailData?.countryCode} ${profileDetailData?.phoneNumber}`
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Email verified
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {" "}
              {profileDetailData?.isEmailverified === 1 ? "Yes " : "No"}
            </Typography>
          </Grid>

          
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Register date
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.registerDate
                ? moment(profileDetailData.registerDate).format("MMM/DD/yyyy")
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
            Deliveries willing to do
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.deliveryWillingToDo?profileDetailData?.deliveryWillingToDo:0
               }
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              How did you hear
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.howDidYouHear
                ? profileDetailData.howDidYouHear
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Available to start
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.availableToStart
                ? moment(profileDetailData.availableToStart).format(
                    "MMM/DD/yyyy"
                  )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Experience to handling
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.haveExperienceToHandling
                ? profileDetailData.haveExperienceToHandling
                : "0"}{" "}
              Years
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Willing to stand by
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.isWillingToStandBy ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Monthly deliveries
            </Typography>
          </Grid>
          <Grid>
            <Box
              sx={{
                background: appColors.topbar,
                textAlign: "center",
                borderRadius: 0.4,
                p: 0.2,
                px:0.6
              }}
              onClick={() =>
                navigate(`/dashboard/monthly/${profileDetailData?.userId}`)
              }
            >
              <Typography
                sx={{
                  color: appColors.whiteColor,
                  fontSize: 13,
                  fontWeight: 400,
                }}
              >
                See more
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Consent
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.deliveryWillingToDo ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Airline worked for
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1.5 }}>
              {profileDetailData?.airlineWorkedFor
                ? profileDetailData.airlineWorkedFor
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
              Active account
            </Typography>
          </Grid>
          <Grid>
            <Switch
              checked={profileDetailData.status === 1 ? true : false}
              onChange={() => {
                setUserStatusDialog((userStatusDialog) => ({
                  ...userStatusDialog,
                  open: true,
                  message: profileDetailData.status == 1 ? "suspend" : "active",
                  funCall: () => handleChange(),
                }));
              }}
              color="warning"
            />
          </Grid>
        </Grid>
        {profileDetailData.ssnFileFullUrl ? (
      

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
                    SSN
                  </Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Icon
                    icon={
                      profileDetailData.ssnVarified == 1
                        ? "bitcoin-icons:verify-filled"
                        : profileDetailData.ssnVarified == 3
                          ? "flat-color-icons:cancel"
                          : ""
                    }
                    color="green"
                    width={
                      profileDetailData.ssnVarified == 1
                        ? "25"
                        : profileDetailData.ssnVarified == 3
                          ? "20"
                          : ""
                    }
                    height="25"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid>
              <Link
                href={profileDetailData.ssnFileFullUrl}
                target={"_blank"}
                sx={{
                  color: appColors.topbar,
                  fontWeight: 500,
                  textDecoration: "none",
                  float: "right",
                }}
              >
                View SSN
              </Link>
              <br />
              <Box sx={{ float: "right" }}>
                {profileDetailData.ssnVarified == 3 ||
                profileDetailData.ssnVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "approve SSN",
                        funCall: () => {
                          // handleApproveReject(1, "SSN", 1);
                          handleStatus(1, 1);
                        },
                      }));
                    }}
                    sx={{
                      color: "green",
                      fontWeight: 800,
                      fontSize: "11px",
                      textDecoration: "none",
                    }}
                  >
                    Approve
                  </Link>
                ) : (
                  ""
                )}
                {profileDetailData.ssnVarified == 1 ||
                profileDetailData.ssnVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "reject SSN ",
                        funCall: () => {
                          // handleApproveReject(1, "SSN", 3);
                          handleStatus(2, 1);
                        },
                      }));
                    }}
                    sx={{
                      color: "red",
                      fontWeight: 800,
                      fontSize: "11px",
                      ml: 1,
                      textDecoration: "none",
                    }}
                  >
                    Reject
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {profileDetailData.passportFileFullUrl ? (
          // <>
          //   <Typography
          //     sx={{
          //       mt: 1.5,
          //       fontWeight: "bold",
          //       fontSize: 18,
          //       color: "#6b7280",
          //     }}
          //   >
          //     Passport
          //   </Typography>
          //   <Link href={profileDetailData.passportFileFullUrl}>
          //     <img
          //       src={profileDetailData.passportFileFullUrl}
          //       width="150px"
          //       style={{ marginTop: 5 }}
          //     />
          //   </Link>
          // </>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Grid>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
                    Passport
                  </Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Icon
                    icon={
                      profileDetailData.passportVarified == 1
                        ? "bitcoin-icons:verify-filled"
                        : profileDetailData.passportVarified == 3
                          ? "flat-color-icons:cancel"
                          : ""
                    }
                    color="green"
                    width={
                      profileDetailData.passportVarified == 1
                        ? "25"
                        : profileDetailData.passportVarified == 3
                          ? "20"
                          : ""
                    }
                    height="25"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid>
              <Link
                href={profileDetailData.passportFileFullUrl}
                target={"_blank"}
                sx={{
                  color: appColors.topbar,
                  fontWeight: 500,
                  textDecoration: "none",
                }}
              >
                View passport
              </Link>
              <br />
              <Box sx={{ float: "right" }}>
                {profileDetailData.passportVarified == 3 ||
                profileDetailData.passportVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "approve passport ",
                        funCall: () => {
                          // handleApproveReject(1, "passport", 1);
                          handleStatus(1, 2);
                        },
                      }));
                    }}
                    sx={{
                      color: "green",
                      fontWeight: 800,
                      fontSize: "11px",
                      textDecoration: "none",
                    }}
                  >
                    Approve
                  </Link>
                ) : (
                  ""
                )}
                {profileDetailData.passportVarified == 1 ||
                profileDetailData.passportVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "reject passport ",
                        funCall: () => {
                          // handleApproveReject(1, "passport", 3);
                          handleStatus(2, 2);
                        },
                      }));
                    }}
                    sx={{
                      color: "red",
                      fontWeight: 800,
                      fontSize: "11px",
                      ml: 1,
                      textDecoration: "none",
                    }}
                  >
                    Reject
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {profileDetailData.docFileFullUrl ? (
        
    
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Grid>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
                    Document
                  </Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Icon
                    icon={
                      profileDetailData.docFileVarified == 1
                        ? "bitcoin-icons:verify-filled"
                        : profileDetailData.docFileVarified == 3
                          ? "flat-color-icons:cancel"
                          : ""
                    }
                    color="green"
                    width={
                      profileDetailData.docFileVarified == 1
                        ? "25"
                        : profileDetailData.docFileVarified == 3
                          ? "20"
                          : ""
                    }
                    height="25"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid>
              <Link
                href={profileDetailData.docFileFullUrl}
                target={"_blank"}
                sx={{
                  color: appColors.topbar,
                  fontWeight: 500,
                  textDecoration: "none",
                }}
              >
                View document
              </Link>
              <br />
              <Box sx={{ float: "right" }}>
                {profileDetailData.docFileVarified == 3 ||
                profileDetailData.docFileVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "approve document file",
                        funCall: () => {
                          // handleApproveReject(1, "document", 1);
                          handleStatus(1, 3);
                        },
                      }));
                    }}
                    sx={{
                      color: "green",
                      fontWeight: 800,
                      fontSize: "11px",
                      textDecoration: "none",
                    }}
                  >
                    Approve
                  </Link>
                ) : (
                  ""
                )}
                {profileDetailData.docFileVarified == 1 ||
                profileDetailData.docFileVarified == 2 ? (
                  <Link
                    href="#"
                    onClick={() => {
                      setUserStatusDialog((userStatusDialog) => ({
                        ...userStatusDialog,
                        open: true,
                        message: "reject document file ",
                        funCall: () => {
                          // handleApproveReject(1, "document", 3);
                          handleStatus(2, 3);
                        },
                      }));
                    }}
                    sx={{
                      color: "red",
                      fontWeight: 800,
                      fontSize: "11px",
                      ml: 1,
                      textDecoration: "none",
                    }}
                  >
                    Reject
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Card>
      <AlertDialog
        open={userStatusDialog?.open}
        title={userStatusDialog?.message}
        text={`Are you sure you want to ${userStatusDialog?.message} of this account? `}
        handleClose={setUserStatusDialog}
        handleAgree={() => userStatusDialog?.funCall()}
      />
    </>
  );
};
export default React.memo(ViewDetail);
