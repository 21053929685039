import { Grid, Typography } from "@mui/material";
import React from "react";

export default function StandbyAirfare() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          Standby Airfare Vs Confirmed Airfare
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14 }}>
          What is the difference between a Standby & Paid delivery?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          Standby Vs Confirmed Airfare
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: 14 }}>
          The "Standby" airfare delivery option we provide is an affordable
          alternative, but it is not guaranteed and based primarily on seat
          availability. The flight itinerary is subject to change at any point
          during the delivery process. We have had high success with this method
          and we closely monitor the flights and update all parties of any
          possible changes to your flight itinerary ASAP but flexibility is a
          MUST.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: 14 }}>
          The "Confirmed" airfare includes a guaranteed plane ticket with flight
          itinerary that is not subject to change unless delayed/cancelled by
          the airline. The pet fee and flight nanny service fee will also be
          included.
        </Typography>
      </Grid>
    </Grid>
  );
}
