import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";
import React from "react";

export default function PreparingForTravel() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          Preparing for Travel
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Typography sx={{ fontSize: 14 }}>
          As the anticipation builds, we want to ensure that this journey is not
          just a transition but a beautiful chapter in the story of your pet’s
          life. Here’s a checklist to guide you through this emotional and
          exciting day:
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: 14 }}>
          The "Standby" airfare delivery option we provide is an affordable
          alternative, but it is not guaranteed and based primarily on seat
          availability. The flight itinerary is subject to change at any point
          during the delivery process. We have had high success with this method
          and we closely monitor the flights and update all parties of any
          possible changes to your flight itinerary ASAP but flexibility is a
          MUST.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon  color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              Crate train at least 1 week before travel
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              Bathe the puppy, kitten, dog or cat the night before
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              Do not feed at least 2-3 hours prior to pickup
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>Potty before pickup</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              Provide a blanket with the mother's scent on it and/or a familiar
              toy
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              Provide a small bag of dry food for the pet
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>
              A harness or collar and leash
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} sx={{mt:0.3}}>
            {" "}
            <Icon color="green" icon="mingcute:checkbox-fill" />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: 14 }}>Health Certificate</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
