import React, { useEffect, useState } from "react";
import { Grid, ThemeProvider, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../theme";
import { useParams } from "react-router-dom";
import Preparing from "../../assets/img/preparingForTravel.png";
import Faq from "../../assets/img/Faq.png";
import Deliver from "../../assets/img/deliver.png";
import StandBy from "../../assets/img/standBy.png";

import StandbyAirfare from "./sections/standbyAirfare";
import PreparingForTravel from "./sections/preparingForTravel";
import Faqs from "./sections/faqs";
import DeliverInternationally from "./sections/deliverInternationally";

export default function SlidePage() {
  const { type } = useParams();
  const [index, setIndex] = useState(-1);
  const data = [
    {
      id: "standbyAirfareVsConfirmedAirfare",
      imageURl: StandBy,
      dec: <StandbyAirfare />,
    },
    {
      id: "preparingForTravel",
      imageURl: Preparing,
      dec: <PreparingForTravel />,
    },
    {
      id: "faqs",
      imageURl: Faq,
      dec: <Faqs />,
    },
    {
      id: "deliverInternationally",
      imageURl: Deliver,
      dec: <DeliverInternationally />,
    },
  ];

  useEffect(() => {
    const suspendIndex = data?.findIndex((item) => item.id === type);
    setIndex(suspendIndex);
  }, [type]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Announcement </title>
      </Helmet>
      <div style={{ widht: "80%" }}>
        <Grid conatiner spacing={2} display="flex" justifyContent={"center"}>
          <Grid item>
            <img
              style={{ objectFit: "contain", height:"fit-content" }}
              src={data[index]?.imageURl}
              width={"100%"}
              height="300rem"
             
            />
          </Grid>
        </Grid>
        <Grid conatiner spacing={2}>
          <Grid item>
            <Typography>{data[index]?.dec}</Typography>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
