import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userSlice from "./slice/users";
import authSlice from "./slice/auth";
import promoCodeSlice from "./slice/promoCode";
import chatSlice from "./slice/chat";


export * from "./slice/auth";
export * from "./slice/users";
export * from "./slice/promoCode"

const rootReducer = combineReducers({
  authSlice,
  userSlice,
  promoCodeSlice,
  chatSlice
});

export const store = configureStore({
  reducer: rootReducer
});
