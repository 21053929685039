import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: "AIzaSyADzyfZX3fp7dVrnvhTG9nu68mRttsw6Kk",
  authDomain: "qt-pet-transport-app.firebaseapp.com",
  projectId: "qt-pet-transport-app",
  storageBucket: "qt-pet-transport-app.appspot.com",
  messagingSenderId: "647353261649",
  appId: "1:647353261649:web:397fcf054ce30543d3a270",
  measurementId: "G-PKNLJ8MV2T"
};


const app = initializeApp(firebaseConfig);
getAnalytics(app);

 const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
   
      resolve(payload);
    });
  });

export const requestForToken = async () => {
  console.log("Requesting User Permission......");
  if (messaging) {
    // const newSw = await navigator.serviceWorker.register(
    //   './firebase-messaging-sw.js'
    // );
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("Notification User Permission Granted.");
        //Voluntary Application Server Identification or VAPID key.
        //{ vapidKey: `BHpyLSxJehOnwFKLws1JPmmZlyQG_YRBLQdsoWljlAEOYCwsRDA2ttU5A9PaxXffCQyiHXupnCPy9g2sxi8LOlg` }
        return getToken(messaging, { vapidKey: `BA6jpcX1Hj7Y0OcfRu_cfBUhp8FXCODtQG_0cQsV2g5oYbskorQl-elxaIiqLTOlV9k3dkfkcnqjG1Hq9EWScRk`,
        //  serviceWorkerRegistration: newSw 
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log('Client Token: ', currentToken);
              Cookies.set('token', currentToken, { secure: true });
            } else {
              console.log('Failed to generate the registration token.');
            }
          })
          .catch((err) => {
            console.log('An error occurred when requesting to receive the token.', err);
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }

}