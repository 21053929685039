/** @format */

import io from "socket.io-client";
import secureLocalStorage from "react-secure-storage";

let userData = secureLocalStorage.getItem(
  process.env.REACT_APP_USER_STORAGE_KEY
);
const loginData = JSON.parse(userData);
console.log("Login Data",loginData)
export const socket = io(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", () => {
  // console.log("Socket connected successfully.");
  socket.emit("join", { userId: loginData?.userId });
});

socket.on("disconnect", () => {
  console.log("Socket Disconnected");
});

socket.on("connect_error", () => {
  // console.log("Socket Connection Error");
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
