import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';

const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  let hls;

  useEffect(() => {
    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(audioRef.current);
      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      audioRef.current.src = src;
    }
  }, [src]);

  return <audio controls ref={audioRef} />;
};

export default AudioPlayer;

