import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import MessageList from "../subcomponent/messageList";
import MessageLayout from "../subcomponent/messageLayout/index";
import ChatHistory from "../subcomponent/chatHistory/index";
import { fetchMessageList } from "../../../../store/slice/chat";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { appColors } from "../../../../theme/colors";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChatTabs(props) {
  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));
  const { getChatListing, setLimit, limit, viewMoreLoader } = props;
  const perPage = 5;
  const [value, setValue] = useState(0);
  const [itemDataId, setItemDataId] = useState();
  const [itemData, setItemData] = React.useState();
  const dispatch = useDispatch();
  const { messageListData, chatListData, groupMember } = useSelector(
    (state) => state.chatSlice
  );
  const loginStorage = secureLocalStorage.getItem(
    process.env.REACT_APP_USER_STORAGE_KEY
  );
  const loginData = JSON.parse(loginStorage);
  const handleChange = (event, newValue) => {
    console.log(newValue,"newValue");
    setValue(newValue);
    if (newValue === 0) {
      console.log("change type");
      getChatListing();
    }
  };
console.log("getchatlist data",chatListData)
  useEffect(() => {
    let index = chatListData?.data
      ? chatListData?.data?.findIndex((item) => item?._id === itemData?._id)
      : chatListData?.findIndex((item) => item?._id === itemData?._id);
    if (chatListData?.length > 0 && chatListData !== undefined) {
      handleLastMessage(chatListData[index === -1 ? 0 : index]);
      // dispatch(setGroupData(chatListData[0]));
    }
  }, [chatListData]);

  // useEffect(() => {
  //   handleLastMessage();
  // }, [groupData]);
  const handleLastMessage = (value) => {
    setItemData(value);
    setItemDataId(value?._id);
    var data = {
      bookingId: value?.bookingId,
      page: "1",
      perPage: perPage ? perPage : "10",
    };
    dispatch(fetchMessageList(data));
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: appColors.topbar,
    },
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
        <Tabs
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: appColors.topbar,
            },
          }}
        >
          <StyledTab label="Chat" {...a11yProps(0)} />
          <StyledTab label="Chat History" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 4}>
            <MessageList
              chatListData={chatListData}
              viewMoreLoader={viewMoreLoader}
              // groupMember={groupMember}
              handleLastMessage={handleLastMessage}
              itemData={itemData}
              itemDataId={itemDataId}
              getChatListing={getChatListing}
              setLimit={setLimit}
              limit={limit}
              messageListData={messageListData}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 8}>
            <MessageLayout
              getChatListing={getChatListing}
              messageListData={messageListData}
              loginData={loginData}
              itemData={itemData}
              groupMember={groupMember}
            />
          </Grid>
        </Grid>{" "}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChatHistory
          itemData={itemData}
          handleLastMessage={handleLastMessage}
        />
      </TabPanel>
    </Box>
  );
}
